import { EXTENDED_HEADER_HEIGHT, HEADER_HEIGHT } from 'constants/globalConstants';

export interface PortalPageThemeProps {
  hasTallerHeading?: boolean;
  hasWiderContainer?: boolean;
}

const PortalPage = {
  parts: ['heading', 'wrapper', 'container'],
  baseStyle: ({ hasTallerHeading, hasWiderContainer }: PortalPageThemeProps) => {
    return {
      wrapper: {
        overflow: 'hidden',
        flexDirection: 'column',
        position: 'relative',
        padding: [`${HEADER_HEIGHT}px 0 4rem`, null, `${EXTENDED_HEADER_HEIGHT}px 0 6rem`],
        bgColor: 'background.lightGrey',
      },
      container: {
        pos: 'relative',
        mx: 'auto',
        px: 4,
        maxW: `max(${hasWiderContainer ? '75' : '66'}%, 1280px)`,
      },
      heading: {
        fontSize: '3xl',
        m: hasTallerHeading ? ['1rem 0 6rem', null, '6rem 0'] : ['1rem 0', null, '3rem 0'],
        textAlign: 'left',
      },
    };
  },
  sizes: {
    xs: {
      container: {
        maxW: '4xl',
      },
    },
  },
  variants: {
    blendIn: {
      heading: {
        mt: [24, 24, 24],
        mb: [0, 0, 0],
        fontWeight: 'bold',
      },
      wrapper: {
        bgColor: 'white',
      },
    },
  },
  defaultProps: {},
};

export default PortalPage;
