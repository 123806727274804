import { translations } from 'new/form/common/types';
import { StepData } from 'new/form/model';

import { CbBankFieldTypes } from '../fields';
import { cbBankSteps } from '../steps';

// TODO: update translation keys to cbbank
const financingNeed = translations.inquiryType.cbBank.pages.financingNeed;
const companyData = translations.inquiryType.cbBank.pages.companyData;
const offer = translations.inquiryType.cbBank.pages.offer;
const personalData = translations.inquiryType.cbBank.pages.personalData;

export const cbBankStepData: StepData<CbBankFieldTypes> = {
  financingNeedPage: {
    progressBarTitle: financingNeed.progressBarTitle,
    nextStep: cbBankSteps.companyDataPage,
    sectionTitles: {
      factoringNeedSection: financingNeed.sections.factoringNeed.title,
      businessDetailsSection: financingNeed.sections.businessDetails.title,
    },
  },
  companyDataPage: {
    progressBarTitle: companyData.progressBarTitle,
    previousStep: cbBankSteps.financingNeedPage,
    nextStep: cbBankSteps.offerPage,
    sectionTitles: {
      companySearchSection: companyData.sections.companySearch.title,
      companyDetailsCompletionSection: companyData.sections.companyDetailsCompletion.title,
      complianceSection: companyData.sections.compliance.title,
    },
  },
  offerPage: {
    progressBarTitle: offer.progressBarTitle,
    previousStep: cbBankSteps.companyDataPage,
    nextStep: cbBankSteps.personalDataPage,
    sectionTitles: {
      contractLaneSection: offer.lanes.contractLane.sectionTitle,
    },
  },
  personalDataPage: {
    progressBarTitle: personalData.progressBarTitle,
    previousStep: cbBankSteps.offerPage,
    sectionTitles: {
      userDataSection: personalData.sections.userData.title,
      legalRepresentativesSection: personalData.sections.legalRepresentatives.title,
      beneficialOwnersSection: personalData.sections.beneficialOwners.title,
      bankAccountSection: personalData.sections.bankAccount.title,
    },
  },
};
