import React from 'react';

import { useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';

import paths from 'constants/paths';
import { InquiryProcessForm } from 'new/form';
import { translations } from 'new/form/common/types';
import { useGetInitialValuesForCreateMode } from 'new/form/persist';
import { LEGAL_REPRESENTATIVE_ERROR } from 'new/legalRepresentatives/LegalRepresentativeCards';
import { SummaryPage } from 'new/summary';
import { createFinalizeSpecializedInquiryAction } from 'pages/inquiryFlow/Rentenbank/useSendSpecializedInquiry';
import { useToasts } from 'shared/hooks/useToasts';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { parseStringBoolean } from 'utils/helpers';
import useDispatchApiCall, { dispatchOptions } from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { cbBankRouteToPageNameMap } from './config/routeToPageNameMap';
import { CbBankFieldTypes } from './fields';
import { CbBankCompanyDataPage } from './steps/companyData';
import { CbBankFinalPage } from './steps/final';
import { CbBankFinancingNeedPage } from './steps/financingNeed';
import { CbBankOfferPage } from './steps/offer';
import { CbBankPersonalDataPage } from './steps/personalData';
import { mapCbBankBankAccount } from './steps/personalData/sections/bankAccount/dataMapper';
import { mapCbBankBeneficialOwners } from './steps/personalData/sections/beneficialOwners/dataMapper';
import { mapCBbankLegalRepresentatives } from './steps/personalData/sections/legalRepresentatives/dataMapper';
import { isValidLegalRepresentatives } from './steps/personalData/sections/legalRepresentatives/validations';
import { mapCbBankUserData } from './steps/personalData/sections/userData/dataMapper';

export const CB_BANK_FORM = 'CB_BANK_FORM';

const useSendCbBankInquiry = () => {
  const t = useTranslations();
  const inquiryId = useSelector(getCreatedInquiryId);
  const { makeCall } = useDispatchApiCall(dispatchOptions(t(translations.errors.submitInquiry)));

  return async (values: CbBankFieldTypes) => {
    const {
      personalDataPage: {
        userDataSection,
        legalRepresentativesSection,
        beneficialOwnersSection,
        bankAccountSection,
      },
    } = values;
    const isAllInformationAvailable = beneficialOwnersSection.allInformationAvailable;
    const getBeneficialOwners = () => {
      if (parseStringBoolean(isAllInformationAvailable)) {
        const beneficialOwners = mapCbBankBeneficialOwners(
          beneficialOwnersSection.beneficialOwners,
        );
        return { beneficiary_owners: beneficialOwners };
      }
      return { beneficiary_owners: [] };
    };
    const apiData = {
      user_attributes: {
        ...mapCbBankUserData(userDataSection),
      },
      legal_representatives: mapCBbankLegalRepresentatives(
        legalRepresentativesSection.legalRepresentatives,
      ),
      beneficiary_owners_data_available: isAllInformationAvailable,
      ...getBeneficialOwners(),
      ...mapCbBankBankAccount(bankAccountSection),
    };

    const sendCbBankInquiry = () =>
      createFinalizeSpecializedInquiryAction(apiData, 'cb_bank', inquiryId);
    return makeCall(sendCbBankInquiry());
  };
};

export const CbBankCreateForm = () => {
  const initialValues = useGetInitialValuesForCreateMode();
  const t = useTranslations();
  const history = useHistory();
  const { error: errorToast } = useToasts();

  const sendInquiry = useSendCbBankInquiry();

  const handleSubmit = async (values: CbBankFieldTypes) => {
    const { error } = await sendInquiry(values);
    if (!error) {
      history.push(paths.finalPage);
    } else {
      console.error(error);
      errorToast({ description: t(translations.errors.submitInquiry) });
    }
    return !error;
  };

  return (
    <InquiryProcessForm<CbBankFieldTypes>
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      validate={(values: CbBankFieldTypes) => {
        const errors: any = {};

        if (!isValidLegalRepresentatives(values)) {
          errors[LEGAL_REPRESENTATIVE_ERROR] = t(
            translations.inquiryType.cbBank.pages.personalData.sections.legalRepresentatives.errors
              .atLeastOneSoleRepresentative,
          );
        }

        return errors;
      }}
    >
      <Switch>
        <Route
          exact
          path={cbBankRouteToPageNameMap.financingNeedPage.create}
          component={CbBankFinancingNeedPage}
        />
        <Route
          exact
          path={cbBankRouteToPageNameMap.companyDataPage.create}
          component={CbBankCompanyDataPage}
        />
        <Route exact path={cbBankRouteToPageNameMap.offerPage.create} component={CbBankOfferPage} />
        <Route
          exact
          path={cbBankRouteToPageNameMap.personalDataPage.create}
          component={CbBankPersonalDataPage}
        />
        <Route exact path={paths.summaryPage} component={SummaryPage<CbBankFieldTypes>} />
        <Route exact path={paths.finalPage} component={CbBankFinalPage} />
      </Switch>
    </InquiryProcessForm>
  );
};
