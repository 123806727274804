import React from 'react';

import { Route, Switch } from 'react-router-dom';

import paths from 'constants/paths';
import CompanyDetails from 'pages/inquiryFlow/CompanyDetails';
import FinancingNeed from 'pages/inquiryFlow/FinancingNeed/FinancingNeedStep';
import RequestDetails from 'pages/inquiryFlow/RequestDetails/RequestDetailsStep';
import { useCaptureUtm } from 'utils/hooks/useCaptureUtm';

import ContactPersonStep from './ContactPersonStep/ContactPersonStep';
import { SpecializedFinalPage } from './FinalPage/SpecializedFinalPage';
import SummaryPage from './SummaryPage/SummaryPageStep';

export const BfsForm: React.FC = () => {
  useCaptureUtm();
  return (
    <Switch>
      <Route exact path={paths.financingNeed} component={FinancingNeed} />
      <Route exact path={paths.companyDetails} component={CompanyDetails} />
      <Route exact path={paths.requestDetails} component={RequestDetails} />
      <Route exact path={paths.contactPersonDetails} component={ContactPersonStep} />

      <Route exact path={paths.summaryPage} component={SummaryPage} />
      <Route exact path={paths.finalPage} component={SpecializedFinalPage} />
    </Switch>
  );
};
