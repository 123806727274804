import * as React from 'react';

import { Box, GridItem, HStack, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import {
  chooseSelectedInquiryTypeSpecificValue,
  useSelectedInquiryTypeSpecificValue,
} from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import {
  getCompanyId,
  getCustomerConfirmationStatus,
  getInquiryCustomerName,
} from 'store/inquiryDetails/selectors';
import {
  DescriptionList,
  DescriptionListDescription,
  DescriptionListTerm,
} from 'theme/components/DescriptionList';
import { LinkComponent } from 'theme/components/Link';
import { useTranslations } from 'utils/hooks/useTranslations';
import { userIsCustomer } from 'utils/user/conditionals';

import CompeonInquiryState from '../CompeonInquiryState';
import CustomerConfirmationStatusLabel from '../ConfirmationStatus/CustomerConfirmationStatusLabel';
import { useSummaryCardData } from '../useSummaryCardData';

interface Props {
  justifyContent?: string;
}

export const AdditionalInformationItem = ({ justifyContent }: Props) => {
  const t = useTranslations();
  const data = useSummaryCardData();
  const isContractLane = data.lane === InquiryLane.contract;
  const isDefault = data.formType === InquiryType.default;
  const isBfsService = data.formType === InquiryType.bfsService;
  const isCustomer = userIsCustomer();
  const companyId = useSelector(getCompanyId);
  const { customerFullName } = useSelector(getInquiryCustomerName);
  const customerConfirmationStatus = useSelector(getCustomerConfirmationStatus);

  const shouldShowCustomerConfirmationStatus =
    useConfig(CONFIG.SHOW_CUSTOMER_CONFIRMATION_STATUS) && !isCustomer;
  const shouldShowInquiryKind =
    useSelectedInquiryTypeSpecificValue({
      [InquiryType.hausbank]: false,
      [InquiryType.bfs]: false,
      [InquiryType.default]: true,
    }) &&
    !isDefault &&
    !isCustomer;

  const shouldShowCompanyName = useSelectedInquiryTypeSpecificValue({
    [InquiryType.cbBank]: !isCustomer,
    [InquiryType.default]: true,
  });
  const shouldShowCompanyNameLink = !isCustomer && !isDefault && !isBfsService;
  const laneTranslationKeys = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: {
      contract: 'pages.inquiryDetails.dashboard.summary.inquiryDigitalContract',
      lead: 'pages.inquiryDetails.dashboard.summary.inquiryTypeDigital',
    },
    [InquiryType.cbBank]: {
      contract: 'inquiryType.cbBank.laneLabels.contract',
      lead: 'inquiryType.cbBank.laneLabels.lead',
    },
  });

  const inquiryKind = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: isContractLane
      ? t(laneTranslationKeys.contract)
      : t(laneTranslationKeys.lead),
    [InquiryType.bfs]: data.status,
  });

  const customerConfirmationStatusLabel = (
    <HStack spacing={2}>
      <Box>{customerFullName}</Box>
      <CustomerConfirmationStatusLabel customerConfirmationStatus={customerConfirmationStatus} />
    </HStack>
  );

  const flexStyleProps = chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.bfs]: {
      justifyContent: 'start',
    },
    [InquiryType.cbBank]: { justifyContent: 'start' },
    [InquiryType.default]: {
      justifyContent: justifyContent || 'flex-end',
    },
  });

  return (
    <GridItem>
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        height="100%"
        paddingBottom={3}
        _before={{
          content: "''",
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '-32px',
          width: '1px',
          backgroundColor: 'background.darkGrey',
        }}
        {...flexStyleProps}
      >
        <VStack spacing="1rem" align="flex-start">
          {shouldShowCompanyName && (
            <DescriptionList>
              <DescriptionListTerm>
                {t('pages.inquiryDetails.dashboard.summary.companyName')}
              </DescriptionListTerm>
              <DescriptionListDescription>
                {shouldShowCompanyNameLink ? (
                  <LinkComponent
                    variant="secondary"
                    href={paths.operation.companies.overview(companyId)}
                  >
                    {data.companyName}
                  </LinkComponent>
                ) : (
                  data?.companyName
                )}
              </DescriptionListDescription>
            </DescriptionList>
          )}
          <Condition condition={shouldShowCustomerConfirmationStatus}>
            <DescriptionList>
              <DescriptionListTerm>
                {t('pages.inquiryDetails.dashboard.summary.customerName')}
              </DescriptionListTerm>
              <DescriptionListDescription>
                {customerConfirmationStatusLabel}
              </DescriptionListDescription>
            </DescriptionList>
          </Condition>
          <Condition condition={!isBfsService}>
            <DescriptionList>
              <DescriptionListTerm>
                {t('pages.inquiryDetails.dashboard.summary.status')}
              </DescriptionListTerm>
              <DescriptionListDescription>{data.status}</DescriptionListDescription>
            </DescriptionList>
          </Condition>

          <CompeonInquiryState />

          {shouldShowInquiryKind && (
            <DescriptionList>
              <DescriptionListTerm>
                {t('pages.inquiryDetails.dashboard.summary.inquiryType')}
              </DescriptionListTerm>
              <DescriptionListDescription>{inquiryKind}</DescriptionListDescription>
            </DescriptionList>
          )}
        </VStack>
      </Box>
    </GridItem>
  );
};
