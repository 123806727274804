import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { cbBankFields } from 'cbbank/inquiry/fields';
import { getCbBankIndustryOptions } from 'cbbank/inquiry/steps/companyData/sections/companyDetailsCompletion/options/industry';
import { getCbBankPaymentTermOptions } from 'cbbank/inquiry/steps/financingNeed/sections/factoringNeed/options/paymentTermOptions';
import { getCbBankSigningPriorityOptions } from 'cbbank/inquiry/steps/personalData/sections/legalRepresentatives/options/signingPriority';
import { useFormConfig } from 'config/formConfig/hooks';
import { getDzbCountryOptions } from 'dzb/inquiry/steps/companyData/sections/companyInformationSection/options/countryOptions';
import {
  STATUS,
  USER_PROFILE_TURNOVER_CLASS,
  FACTORING_BILLS_RANGE,
  FACTORING_AVERAGE_PERIOD_OF_PAYMENT,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  FACTORING_TURNOVER_CLASS,
  HIRE_PURCHASE_AMORTISATION_KIND,
  REAL_ESTATE_KIND,
  MACHINE_CATEGORY,
  CORONA_FINANCING_OPTION,
  CORONA_FINANCING_OPTION__KFW_PROGRAM,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
  PROJECT_FINANCING__FURNISHING_QUALITY,
  PROJECT_FINANCING__QUALITY_CONDITION,
  BFS_SERVICE__PURPOSE_KIND,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
  BFS_SERVICE__FACTORING_OFFER,
  PRE_FINANCING_PERIOD,
  COMPANY_DETAILS_GENDER,
  BENEFICIARY_GENDER,
  REPRESENTATIVE_GENDER,
  FURTHER_NEED_VALUES,
} from 'modules/Inquiry/Form/formFields';
import getBfsServicePurposeKindOptions from 'modules/Inquiry/Form/Steps/FinancingNeed/Purpose/BfsServicePurposeSection/bfsServicePurposeKindOptions';
import getFactoringOfferOptions from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BfsServiceSections/factoringOfferOptions';
import getIndustrySoftwareForBillingOptions from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BfsServiceSections/IndustrySoftwareForBillingOptions';
import getPrefinancingPeriodOptions from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BfsServiceSections/PrefinancingPeriodOptions';
import getPurchasedInvoicesPaidOutOnOptions from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/BfsServiceSections/purchasedInvoicesPaidOutOnOptions';
import getProjectFinancingConditionQualityOptions from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ObjectInformation/projectFinancingConditionQualityOptions';
import getProjectFinancingFurnishingQualityOptions from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/ProjectFinancing/ObjectInformation/projectFinancingFurnishingQualityOptions';
import getFactoringAnnualNetProfitOrLossClassOptions from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Factoring/factoringAnnualNetProfitOrLossClassOptions';
import getFactoringAveragePeriodOfPaymentOptions from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Factoring/factoringAveragePeriodOfPaymentOptions';
import getFactoringBillsRangeOptions from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Factoring/factoringBillsRangeOptions';
import getFactoringTurnoverClassOptions from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Factoring/factoringTurnoverClassOptions';
import { getHirePurchaseAmmortisationKindOptions } from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/HirePurchase/service';
import { getFurtherNeedOptions } from 'modules/Inquiry/Form/Steps/RequestDetails/RequestDetails/getFurtherNeedOptions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { companyDetailsValues } from 'modules/Inquiry/inquiryFieldsTranslations/valueTranslations/companyDetailsValues';
import { financingNeedValues } from 'modules/Inquiry/inquiryFieldsTranslations/valueTranslations/financingNeedValues';
import { requestDetailsValues } from 'modules/Inquiry/inquiryFieldsTranslations/valueTranslations/requestDetailsValues';
import { userProfileValues } from 'modules/Inquiry/inquiryFieldsTranslations/valueTranslations/userProfileValues';
import { allStatuses, translateInquiryStatus } from 'modules/Inquiry/inquiryStatuses';
import getTurnoverClassOptions from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/TurnoverClass/turnoverClassOptions';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getCountryOptions } from 'shared/options/country';
import { getNationalityOptions } from 'shared/options/nationality';
import { useFormValues } from 'store/hooks/useFormValues';
import {
  getMachineCategoriesOptions,
  getPurchaseFinancingObjectsOptions,
  getRealEstateFinancingObjectsOptions,
} from 'store/partner/config/selectors';
import { useObjectValueTranslator } from 'utils/hooks/useObjectValueTranslator';

import { dzbFields } from '../../../../dzb/inquiry/fields';
import { getDzbTypeOfFinancialStatementsOptions } from '../../../../dzb/inquiry/steps/companyData/sections/companyInformationSection/options/typeOfFinancialStatementOptions';
import { hausbankFields } from '../../../../hausbank/inquiry/fields';
import { getHausbankLoanTermInYearsOptions } from '../../../../hausbank/inquiry/steps/financingNeed/sections/financingNeedSection/options/loanTermInYearsOptions';
import { getHausbankPurposeKindOptions } from '../../../../hausbank/inquiry/steps/financingNeed/sections/purposeSection/options/purposeKindOptions';
import { mittweidaFields } from '../../../../mittweida/inquiry/fields';
import { getMittweidaConditionQualityOptions } from '../../../../mittweida/inquiry/steps/financingNeed/sections/objectInformationSection/options/conditionQualityOptions';
import { getMittweidaFurnishingQualityOptions } from '../../../../mittweida/inquiry/steps/financingNeed/sections/objectInformationSection/options/furnishingQualityOptions';
import { getMittweidaObjectTypeOptions } from '../../../../mittweida/inquiry/steps/financingNeed/sections/objectInformationSection/options/objectTypeOptions';
import { getMittweidaUsageTypeOptions } from '../../../../mittweida/inquiry/steps/financingNeed/sections/objectInformationSection/options/usageTypeOptions';
import { getMittweidaPermitBasisOptions } from '../../../../mittweida/inquiry/steps/financingNeed/sections/otherProjectInformationSection/options/permitBasisOptions';
import { getMittweidaPermitStatusOptions } from '../../../../mittweida/inquiry/steps/financingNeed/sections/otherProjectInformationSection/options/permitStatusOptions';
import { getMittweidaRoleSelectOptions } from '../../../../mittweida/inquiry/steps/financingNeed/sections/roleSection/options/roleOptions';
import { getGenderOptions } from '../../../../mittweida/inquiry/steps/userProfile/sections/contactPerson/options/genderOptions';
import { mmvFields } from '../../../../mmv/inquiry/fields';
import { getMittweidaIndustryDefaultOptions } from '../../../../ui/companyDetails/industry/select/useGetIndustryOptions';

export const reduceToTranslatedMap = (accumulator, option) => ({
  ...accumulator,
  [option.value]: option.label,
});

const useDynamicInquiryTranslations = () => {
  // Some options are build dynamically and has to be translated on generating
  const { formatMessage } = useIntl();
  const { dropdowns } = useFormConfig();
  const formValues = useFormValues();

  const defaultTranslations = useMemo(
    () => ({
      [STATUS]: allStatuses.reduce(
        (acc, status) => ({ ...acc, [status]: translateInquiryStatus(formatMessage, status) }),
        {},
      ),
      [USER_PROFILE_TURNOVER_CLASS]: {
        ...getTurnoverClassOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [FACTORING_BILLS_RANGE]: {
        ...getFactoringBillsRangeOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [FACTORING_AVERAGE_PERIOD_OF_PAYMENT]: {
        ...getFactoringAveragePeriodOfPaymentOptions(formatMessage).reduce(
          reduceToTranslatedMap,
          {},
        ),
      },
      [FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS]: {
        ...getFactoringAnnualNetProfitOrLossClassOptions(formatMessage).reduce(
          reduceToTranslatedMap,
          {},
        ),
      },
      [FACTORING_TURNOVER_CLASS]: {
        ...getFactoringTurnoverClassOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [HIRE_PURCHASE_AMORTISATION_KIND]: {
        ...getHirePurchaseAmmortisationKindOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [REAL_ESTATE_KIND]: {
        ...getRealEstateFinancingObjectsOptions(formatMessage, dropdowns).reduce(
          reduceToTranslatedMap,
          {},
        ),
        ...getPurchaseFinancingObjectsOptions(formatMessage, dropdowns).reduce(
          reduceToTranslatedMap,
          {},
        ),
      },
      [MACHINE_CATEGORY]: {
        ...getMachineCategoriesOptions(formatMessage, dropdowns).reduce(reduceToTranslatedMap, {}),
      },
      [PROJECT_FINANCING__FURNISHING_QUALITY]: {
        ...getProjectFinancingFurnishingQualityOptions(formatMessage, dropdowns).reduce(
          reduceToTranslatedMap,
          {},
        ),
      },
      [PROJECT_FINANCING__QUALITY_CONDITION]: {
        ...getProjectFinancingConditionQualityOptions(formatMessage, dropdowns).reduce(
          reduceToTranslatedMap,
          {},
        ),
      },
    }),
    [formatMessage, dropdowns],
  );

  const translationsForCurrentPartner = useSelectedInquiryTypeSpecificValue({
    [InquiryType.profiMittweida]: {
      [mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company.industry]: {
        ...getMittweidaIndustryDefaultOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [mittweidaFields.companyDetailPage.completingCompanyDetailsSection.company.turnoverClass]: {
        ...getTurnoverClassOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [mittweidaFields.userProfilePage.contactPersonSection.gender]: {
        ...getGenderOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [mittweidaFields.financingNeedPage.roleSection.projectFinancingRole]: {
        ...getMittweidaRoleSelectOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [mittweidaFields.financingNeedPage.objectInformationSection.furnishingQuality]: {
        ...getMittweidaFurnishingQualityOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [mittweidaFields.financingNeedPage.objectInformationSection.qualityCondition]: {
        ...getMittweidaConditionQualityOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [mittweidaFields.financingNeedPage.objectInformationSection.futureObjectType]: {
        ...getMittweidaObjectTypeOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [mittweidaFields.financingNeedPage.objectInformationSection.currentObjectType]: {
        ...getMittweidaObjectTypeOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [mittweidaFields.financingNeedPage.objectInformationSection.futureUsage.typeOfUse]: {
        ...getMittweidaUsageTypeOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [mittweidaFields.financingNeedPage.objectInformationSection.currentUsage.typeOfUse]: {
        ...getMittweidaUsageTypeOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [mittweidaFields.financingNeedPage.otherProjectInformation.permitBasis]: {
        ...getMittweidaPermitBasisOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [mittweidaFields.financingNeedPage.otherProjectInformation.permitStatus]: {
        ...getMittweidaPermitStatusOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
    },
    [InquiryType.mmv]: {
      [mmvFields.customerDataPage.contactPersonSection.title]: {
        ...getGenderOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
    },
    [InquiryType.hausbank]: {
      [hausbankFields.personalDataPage.contactPersonSection.salutation]: {
        ...getGenderOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [hausbankFields.personalDataPage.legalRepresentativesSection.legalRepresentatives.salutation]:
        {
          ...getGenderOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
        },
      [hausbankFields.financingNeedPage.purposeSection.purposeKind]: {
        ...getHausbankPurposeKindOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [hausbankFields.financingNeedPage.financingNeedSection.loanTermInYears]: {
        ...getHausbankLoanTermInYearsOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
    },
    [InquiryType.dzb]: {
      [dzbFields.companyDataPage.companyInformationSection.typeOfFinancialStatements]: {
        ...getDzbTypeOfFinancialStatementsOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [dzbFields.companyDataPage.companyInformationSection.country]: {
        ...getDzbCountryOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [dzbFields.personalDataPage.legalRepresentativesSection.legalRepresentatives.salutation]: {
        ...getGenderOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
    },
    [InquiryType.cbBank]: {
      [cbBankFields.financingNeedPage.factoringNeedSection.paymentTerm]: {
        ...getCbBankPaymentTermOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [cbBankFields.companyDataPage.companyDetailsCompletionSection.industry]: {
        ...getCbBankIndustryOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [cbBankFields.personalDataPage.userDataSection.salutation]: {
        ...getGenderOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [cbBankFields.personalDataPage.legalRepresentativesSection.legalRepresentatives.salutation]: {
        ...getGenderOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [cbBankFields.personalDataPage.legalRepresentativesSection.legalRepresentatives
        .signingPriority]: {
        ...getCbBankSigningPriorityOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [cbBankFields.personalDataPage.beneficialOwnersSection.beneficialOwners.nationality]: {
        ...getNationalityOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
      [cbBankFields.personalDataPage.beneficialOwnersSection.beneficialOwners.countryOfResidence]: {
        ...getCountryOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
    },
    [InquiryType.bfsService]: {
      [BFS_SERVICE__PURPOSE_KIND]: {
        ...getBfsServicePurposeKindOptions(formatMessage, dropdowns).reduce(
          reduceToTranslatedMap,
          {},
        ),
      },
      [BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING]: {
        ...getIndustrySoftwareForBillingOptions(formValues).reduce(reduceToTranslatedMap, {}),
      },
      [BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON]: {
        ...getPurchasedInvoicesPaidOutOnOptions(formatMessage, formValues).reduce(
          reduceToTranslatedMap,
          {},
        ),
      },
      [PRE_FINANCING_PERIOD]: {
        ...getPrefinancingPeriodOptions(formatMessage, dropdowns).reduce(reduceToTranslatedMap, {}),
      },
      [BFS_SERVICE__FACTORING_OFFER]: {
        ...getFactoringOfferOptions(formatMessage, dropdowns).reduce(reduceToTranslatedMap, {}),
      },
      [FURTHER_NEED_VALUES]: {
        ...getFurtherNeedOptions(formatMessage).reduce(reduceToTranslatedMap, {}),
      },
    },
    default: {},
  });

  return { ...defaultTranslations, ...translationsForCurrentPartner };
};

const translationMap = {
  ...financingNeedValues,
  ...companyDetailsValues,
  ...requestDetailsValues,
  ...userProfileValues,
  [CORONA_FINANCING_OPTION]: {
    [CORONA_FINANCING_OPTION__KFW_PROGRAM]:
      'pages.coronaStep.fields.financingOption.options.kfwProgram',
    [CORONA_FINANCING_OPTION__QUICK_CREDIT]:
      'pages.coronaStep.fields.financingOption.options.quickCredit',
  },
  [BENEFICIARY_GENDER]: companyDetailsValues[COMPANY_DETAILS_GENDER],
  [REPRESENTATIVE_GENDER]: companyDetailsValues[COMPANY_DETAILS_GENDER],
};

/*
 * Returns map:
 * {
 *   [FIELD_NAME]: {
 *     [FIELD_OPTION]: "Translated option",
 *     [FIELD_OPTION2]: "Translated second option"
 *     ....
 *   }
 * }
 */
export const useValueTranslationMap = () => {
  const objTranslator = useObjectValueTranslator();
  const staticTranslationMap = objTranslator(translationMap);
  const dynamicTranslationMap = useDynamicInquiryTranslations();
  return useMemo(
    () => ({ ...staticTranslationMap, ...dynamicTranslationMap }),
    [dynamicTranslationMap, staticTranslationMap],
  );
};
