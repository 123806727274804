import { CbBankSigningPriority } from 'cbbank/inquiry/fields';
import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { translations } from 'new/form/common/types';
import { FormatMessage } from 'utils/intl';

export const getCbBankSigningPriorityOptions = (
  formatMessage: FormatMessage,
): SelectOption<CbBankSigningPriority>[] => {
  const { first, second, none } =
    translations.inquiryType.cbBank.pages.personalData.sections.legalRepresentatives.fields
      .legalRepresentative.fields.signingPriority.values;

  const options: Array<{ id: string; value: CbBankSigningPriority }> = [
    {
      id: first,
      value: CbBankSigningPriority.FIRST,
    },
    {
      id: second,
      value: CbBankSigningPriority.SECOND,
    },
    {
      id: none,
      value: CbBankSigningPriority.NONE,
    },
  ];

  return options.map(({ id, value }) => {
    return {
      value,
      label: formatMessage({ id }),
    };
  });
};
