import React from 'react';

import { useForm } from 'react-final-form';
import { useIntl } from 'react-intl';

import { useCbBankConditions } from 'cbbank/inquiry/config/conditions';
import { CbBankFieldTypes, cbBankFields } from 'cbbank/inquiry/fields';
import Condition from 'components/Condition';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { SelectWithField } from 'components/Selects/Select';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { EXISTING_USER_COMPANY_NAME } from 'modules/Inquiry/Form/formFields';
import { translations } from 'new/form/common/types';
import { useGetCompany } from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useGetCompany';
import StaticCompanyInformation, {
  StaticCompany,
} from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/CompanyInformationForm/StaticCompanyInformation';
import { StringBoolean } from 'types/General';
import { hasExistingCompany, useGetValidCompanyNameFieldName } from 'utils/company';
import { parseStringBoolean } from 'utils/helpers';
import { useTranslations } from 'utils/hooks/useTranslations';

import CbBankFillCompanyInformation from './CbBankFillCompanyInformation';
import { getCbBankIndustryOptions } from './options/industry';
import { getCbBankLegalFormOptions } from './options/legalForm';

export const CompanyDetailsCompletionSection = () => {
  const t = useTranslations();
  const { formatMessage } = useIntl();
  const { change } = useForm();
  const conditionValues = useCbBankConditions();
  const {
    title,
    fields: {
      name: { caption: nameCaption },
      street: { caption: addressCaption },
      subjectToAccounting: { tooltip: subjectToAccountingTooltip },
      positiveEquity: { tooltip: positiveEquityTooltip },
      positiveAnnualResult: { tooltip: positiveAnnualResultTooltip },
    },
  } = translations.inquiryType.cbBank.pages.companyData.sections.companyDetailsCompletion;

  const {
    companyDataPage: {
      companySearchSection: { selectedCompanyName: selectedCompanyNameFieldName },
      companyDetailsCompletionSection: {
        street,
        zipCode,
        city,
        legalForm,
        industry,
        olderThan2Years,
        subjectToAccounting,
        positiveEquity,
        positiveAnnualResult,
      },
    },
  } = cbBankFields;

  const companyNameFieldName = useGetValidCompanyNameFieldName<CbBankFieldTypes>(
    selectedCompanyNameFieldName,
  );
  const config: StaticCompany = {
    name: companyNameFieldName,
    city,
    street,
    'zip-code': zipCode,
  };
  const company = useGetCompany(config);
  const { [EXISTING_USER_COMPANY_NAME]: companyName } = company;
  const existingCompany = hasExistingCompany(companyName);

  return (
    <FormSection title={t(title)} hideSectionNumber>
      <Condition condition={existingCompany}>
        <StaticCompanyInformation
          company={company}
          companyNameCaption={t(nameCaption)}
          companyAddressCaption={t(addressCaption)}
        />
      </Condition>
      <Condition condition={!existingCompany}>
        <CbBankFillCompanyInformation />
      </Condition>
      <FormRow>
        <SelectWithField name={legalForm} options={getCbBankLegalFormOptions(formatMessage)} />
      </FormRow>
      <FormRow>
        <SelectWithField name={industry} options={getCbBankIndustryOptions(formatMessage)} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={olderThan2Years} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField
          name={subjectToAccounting}
          tooltip={t(subjectToAccountingTooltip)}
          onChange={(value: StringBoolean) => {
            if (!parseStringBoolean(value)) {
              change(positiveEquity, undefined);
              change(positiveAnnualResult, undefined);
            }
          }}
        />
      </FormRow>
      <Condition condition={conditionValues[positiveEquity]}>
        <FormRow>
          <YesNoRadioGroupWithField name={positiveEquity} tooltip={t(positiveEquityTooltip)} />
        </FormRow>
      </Condition>
      <Condition condition={conditionValues[positiveAnnualResult]}>
        <FormRow>
          <YesNoRadioGroupWithField
            name={positiveAnnualResult}
            tooltip={t(positiveAnnualResultTooltip)}
          />
        </FormRow>
      </Condition>
    </FormSection>
  );
};
