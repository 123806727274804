import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { formatDateDays, formatDateToYear, formatMonthWithYear } from 'utils/date';
import {
  formatDecimalWithOutPrecision,
  formatKilometer,
  formatPercentage,
  formatPrice,
  formatPriceAsThousands,
  replaceDotWithComma,
  formatPercentageStringWithPrecision2,
} from 'utils/valueFormats';

export enum summaryFieldUnits {
  defaultCurrency = 'defaultCurrency',
  currency = 'currency',
  thousandsCurrency = 'thousandsCurrency',
  currencyMax2Decimals = 'currencyMax2Decimals',
  currency2Decimals = 'currency2Decimals',
  squareMeters = 'squareMeters',
  quadraMeters = 'quadraMeters',
  kilometers = 'kilometers',
  years = 'years',
  year = 'year',
  months = 'months',
  percentage = 'percentage',
  percentageDecimal = 'percentageDecimal',
  percentageDecimalString = 'percentageDecimalString',
  date = 'date',
  dateinput = 'dateinput',
  from1000 = 'from1000',
  m2m3 = 'm2m3',
  monthAndYear = 'monthAndYear',
  invoices = 'invoices',
  days = 'days',
  currencyPerMonth = 'currencyPerMonth',
  none = 'none',
}

export type FieldUnitType = keyof typeof summaryFieldUnits;

export type SummaryType = {
  unit: FieldUnitType;
  showOnSummaryPage: boolean;
  order?: number;
  showEmpty?: boolean;
  testId?: string;
  label?: (inquiry: Partial<IInquiryDetails> | null) => string | null;
  render?: (text: string) => React.ReactNode;
};

export const unitFormatters: { [key in FieldUnitType]: Function | undefined } = {
  defaultCurrency: formatPrice,
  currency: formatPrice,
  thousandsCurrency: formatPriceAsThousands,
  currencyMax2Decimals: (price: number) => formatPrice(price, true, 0, 2, true),
  currency2Decimals: (price: number) => formatPrice(price, true, 2, 2, true),
  squareMeters: replaceDotWithComma,
  quadraMeters: undefined,
  kilometers: formatKilometer,
  years: undefined,
  year: formatDateToYear,
  months: undefined,
  percentage: replaceDotWithComma,
  percentageDecimal: formatPercentage,
  percentageDecimalString: formatPercentageStringWithPrecision2,
  date: formatDateDays,
  dateinput: undefined,
  from1000: undefined,
  m2m3: undefined,
  invoices: undefined,
  days: formatDecimalWithOutPrecision,
  monthAndYear: formatMonthWithYear,
  none: undefined,
  currencyPerMonth: undefined,
};

export const unitTypeSuffixMap: { [key in FieldUnitType]: (translator: Function) => any } = {
  defaultCurrency: (translator) => translator('other.defaultCurrency'),
  currency: (translator) => translator('currency.euro'),
  thousandsCurrency: (translator) => translator('currency.thousandEuro'),
  currencyMax2Decimals: () => null,
  currency2Decimals: () => null,
  squareMeters: (translator) => translator('other.m2'),
  quadraMeters: (translator) => translator('other.qm'),
  kilometers: (translator) => translator('other.kilometers'),
  years: (translator) => translator('other.years'),
  year: () => null,
  months: (translator) => translator('other.months'),
  percentage: (translator) => translator('other.percent'),
  percentageDecimal: (translator) => translator('other.percent'),
  percentageDecimalString: (translator) => translator('other.percent'),
  date: () => null,
  dateinput: () => null,
  from1000: (translator) => translator('other.from1000'),
  m2m3: (translator) => translator('other.m2m3'),
  invoices: (translator) => translator('other.invoices'),
  days: (translator) => translator('other.days'),
  monthAndYear: () => null,
  none: () => null,
  currencyPerMonth: (translator) => translator('other.currencyPerMonth'),
};
