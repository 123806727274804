import React, { useState } from 'react';

import { Box, useDisclosure } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import archiveInquiryAction from 'api/CompeonReverseApi/operation/archiveInquiry/actions';
import { ErrorText } from 'components/Text';
import queryKeys from 'constants/queryKeys';
import PERMISSIONS from 'constants/user/permissions';
import disableInquiryDraft from 'modules/Inquiry/DraftMode/disableInquiryDraft';
import withRolesGuard from 'shared/guard/withRolesGuard';
import useInquiryPermissions from 'shared/hooks/useInquiryPermissionsHook';
import { useToasts } from 'shared/hooks/useToasts';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { AlertDialogComponent } from 'theme/components/AlertDialog';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import Form from './Form';
import CancelInquiry from '../CancelInquiry';

const ArchiveInquiry = () => {
  const t = useTranslations('pages.inquiryDetails.dashboard.closeInquiry.archiveInquiry');
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const dispatch = useDispatch();
  const { makeCall } = useDispatchApiCall();
  const { canArchive } = useInquiryPermissions();
  const { success } = useToasts();
  const queryClient = useQueryClient();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState(null);
  const [archivingReason, setArchivingReason] = useState(null);

  function handleFormSubmit(formValues) {
    setArchivingReason(formValues.archivingReason);
    setError(null);
    onOpen();
  }

  function handleModalClose() {
    onClose();
    setArchivingReason(null);
  }

  async function archiveInquiry() {
    const response = await makeCall(
      archiveInquiryAction(inquiryDetails.inquiryId, archivingReason),
    );

    if (response.error) {
      setError(response.error.message);
    } else {
      success({ description: t('inquiryIsArchived') });
      dispatch(mapInquiryDetailsApiResponseAction(response.payload.data));
      queryClient.invalidateQueries([queryKeys.inquiries.details]);
      handleModalClose();
    }
  }

  const standardArchivedReason = _get(inquiryDetails, 'archivingReason');
  const agriculturalArchivedReason = _get(inquiryDetails, 'actions.archivingReason');

  return (
    <>
      {canArchive ? (
        <>
          <Form onSubmit={handleFormSubmit}>
            <CancelInquiry
              mt={4}
              ml={2}
              variant="full"
              inquiryId={inquiryDetails.inquiryId}
              status={inquiryDetails.status}
            >
              {t('cancelInquiry.action')}
            </CancelInquiry>
            {error && <ErrorText>{error}</ErrorText>}
          </Form>

          <AlertDialogComponent
            cancelText={t('modal.declineBtn')}
            confirmText={t('modal.confirmationBtn')}
            isOpen={isOpen}
            onClose={handleModalClose}
            onConfirm={archiveInquiry}
            title={t('modal.heading')}
          >
            {t('modal.confirmationText')}
          </AlertDialogComponent>
        </>
      ) : (
        <Box>
          <HeadingComponent as="h5" mb={4} pt={1} fontWeight="initial" variant="tertiary">
            {t('inquiryIsArchived')}
          </HeadingComponent>
          <TextComponent color="text.secondary">
            {t('reason')}: {standardArchivedReason ?? agriculturalArchivedReason}
          </TextComponent>
        </Box>
      )}
    </>
  );
};

export default compose(
  withRolesGuard(PERMISSIONS.INQUIRY.ARCHIVE),
  disableInquiryDraft,
)(ArchiveInquiry);
