import {
  COMPEON_PARTNER,
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  PRODUCT_KIND,
} from 'modules/Inquiry/Form/formFields';
import { mapCompanyDetailsToApi } from 'modules/Inquiry/mapInquiryToApi/companyMappers/mapCompanyDetailsToApi';
import { mapFinancingNeedPurposeFieldsToApi } from 'modules/Inquiry/mapInquiryToApi/financialNeedMappers/mapFinancingNeedPurposeFieldsToApi';
import { mapBFSUserToApi } from 'modules/Inquiry/mapInquiryToApi/userMappers/mapUserDetailsToApi';
import { getUTMData } from 'utils/sessionStorage/helpers';

import { mapBFSProductDetailsToApi } from './productKindMappers/mapBFSProductDetailsToApi';

export const mapBFSInquiryToApi = (allFields: any, isCustomer = false) => {
  return {
    'compeon-partner': allFields[COMPEON_PARTNER],
    'additional-association-company': allFields[USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY],
    'product-kind': allFields[PRODUCT_KIND],
    ...mapFinancingNeedPurposeFieldsToApi(allFields),
    ...mapBFSProductDetailsToApi(allFields),
    ...mapCompanyDetailsToApi(allFields),
    ...mapBFSUserToApi(allFields, isCustomer),
    ...getUTMData(),
  };
};
