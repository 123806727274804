import { LabelsMap } from 'new/form/common/types';

import { bankAccountSectionLabelsMap } from './sections/bankAccount/labels';
import { beneficialOwnersSectionLabelsMap } from './sections/beneficialOwners/labels';
import { legalRepresentativesSectionLabelsMap } from './sections/legalRepresentatives/labels';
import { userDateSectionLabelsMap } from './sections/userData/labels';

export const personalDatePageLabelsMap: LabelsMap = {
  ...userDateSectionLabelsMap,
  ...legalRepresentativesSectionLabelsMap,
  ...beneficialOwnersSectionLabelsMap,
  ...bankAccountSectionLabelsMap,
};
