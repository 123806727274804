import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { createMutation } from 'react-query-kit';
import { useSelector } from 'react-redux';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { translations } from 'new/form/common/types';
import {
  DzbLegalRepresentative,
  LegalRepresentative,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import {
  mapDzbLegalRepresentativeToBackend,
  mapLegalRepresentativeToBackend,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/mappers';
import { ASSOCIATED_PEOPLE_QUERY_KEY } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/hooks/useFetchAssociatedPeople';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const usePatchDzbLegalRepresentativeMutation = createMutation({
  mutationFn: async (variables: {
    inquiryId: string;
    legalRepresentative: DzbLegalRepresentative;
  }) => {
    return EcoBankingAxiosClientAuthedInstance.patch(
      endpoints.INQUIRIES.SPECIALIZED.DZB.LEGAL_REPRESENTATIVES.PATCH.compose({
        params: {
          id: variables.inquiryId,
          legalRepresentativeId: variables.legalRepresentative.id ?? '',
        },
      }),
      {
        data: {
          attributes: mapDzbLegalRepresentativeToBackend(variables.legalRepresentative),
        },
      },
    );
  },
});

export function usePatchDzbLegalRepresentative() {
  const inquiryId = useSelector(getInquiryIdSelector);
  const toasts = useToasts();
  const t = useTranslations();
  const { successMessage, errorMessage } =
    translations.pages.companiesDetails.associatedPerson.new.legalRepresentatives.actions.edit;
  const queryClient = useQueryClient();

  const mutation = usePatchDzbLegalRepresentativeMutation({
    onSuccess: () => {
      toasts.success({ description: t(successMessage) });
      queryClient.invalidateQueries([ASSOCIATED_PEOPLE_QUERY_KEY]);
    },
    onError: () => {
      toasts.error({ description: t(errorMessage) });
    },
  });

  const mutateAsync = useCallback(
    (legalRepresentative: DzbLegalRepresentative) => {
      return mutation.mutateAsync({
        inquiryId,
        legalRepresentative,
      });
    },
    [mutation, inquiryId],
  );

  return {
    ...mutation,
    mutateAsync,
  };
}

const usePatchCbBankLegalRepresentativeMutation = createMutation({
  mutationFn: async (variables: {
    inquiryId: string;
    legalRepresentative: LegalRepresentative;
  }) => {
    return EcoBankingAxiosClientAuthedInstance.patch(
      endpoints.INQUIRIES.SPECIALIZED.CB_BANK.LEGAL_REPRESENTATIVES.PATCH.compose({
        params: {
          id: variables.inquiryId,
          legalRepresentativeId: variables.legalRepresentative.id ?? '',
        },
      }),
      {
        data: {
          attributes: mapLegalRepresentativeToBackend(variables.legalRepresentative),
        },
      },
    );
  },
});

export function usePatchCbBankLegalRepresentative() {
  const inquiryId = useSelector(getInquiryIdSelector);
  const toasts = useToasts();
  const t = useTranslations();
  const { successMessage, errorMessage } =
    translations.pages.companiesDetails.associatedPerson.new.legalRepresentatives.actions.edit;
  const queryClient = useQueryClient();

  const mutation = usePatchCbBankLegalRepresentativeMutation({
    onSuccess: () => {
      toasts.success({ description: t(successMessage) });
      queryClient.invalidateQueries([ASSOCIATED_PEOPLE_QUERY_KEY]);
    },
    onError: () => {
      toasts.error({ description: t(errorMessage) });
    },
  });

  const mutateAsync = useCallback(
    (legalRepresentative: LegalRepresentative) => {
      return mutation.mutateAsync({
        inquiryId,
        legalRepresentative,
      });
    },
    [mutation, inquiryId],
  );

  return {
    ...mutation,
    mutateAsync,
  };
}
