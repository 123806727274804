import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ValidationRecord } from 'new/form/common/types';
import { InquiryProcessValidations } from 'new/form/model';

import { validateCompanyDetailsCompletionSection } from '../steps/companyData/sections/companyDetailsCompletion/validations';
import { validateCompanySearchSection } from '../steps/companyData/sections/companySearch/validations';
import { validateComplianceSection } from '../steps/companyData/sections/compliance/validations';
import { validateBusinessDetailsSection } from '../steps/financingNeed/sections/businessDetails/validations';
import { validateFactoringNeedSection } from '../steps/financingNeed/sections/factoringNeed/validations';
import { validateContractLaneSection } from '../steps/offer/sections/contractLane/validations';
import { validateBankAccountSection } from '../steps/personalData/sections/bankAccount/validations';
import { validateBeneficialOwnersSection } from '../steps/personalData/sections/beneficialOwners/validations';
import { validateLegalRepresentativesSection } from '../steps/personalData/sections/legalRepresentatives/validations';
import { validateUserDataSection } from '../steps/personalData/sections/userData/validations';

const cbBankDefaultValidations: ValidationRecord<InquiryType.cbBank> = {
  financingNeedPage: {
    factoringNeedSection: validateFactoringNeedSection,
    businessDetailsSection: validateBusinessDetailsSection,
  },
  companyDataPage: {
    companySearchSection: validateCompanySearchSection,
    companyDetailsCompletionSection: validateCompanyDetailsCompletionSection,
    complianceSection: validateComplianceSection,
  },
  offerPage: {
    contractLaneSection: validateContractLaneSection,
  },
  personalDataPage: {
    userDataSection: validateUserDataSection,
    legalRepresentativesSection: validateLegalRepresentativesSection,
    beneficialOwnersSection: validateBeneficialOwnersSection,
    bankAccountSection: validateBankAccountSection,
  },
};

export const cbBankValidations: InquiryProcessValidations<InquiryType.cbBank> = {
  create: cbBankDefaultValidations,
  editCustomer: cbBankDefaultValidations,
  editOperation: cbBankDefaultValidations,
};
