import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { UTM_DATA_SESSION_STORAGE_KEY } from 'utils/sessionStorage/keys';

const TRACKED_PARAMETERS = chooseSelectedInquiryTypeSpecificValue({
  [InquiryType.default]: [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ] as const,
});

export type UtmData = Partial<Record<(typeof TRACKED_PARAMETERS)[number], string>>;

export const useCaptureUtm = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    sessionStorage.removeItem(UTM_DATA_SESSION_STORAGE_KEY);
    const utmParameters: UtmData = {};
    for (const key of TRACKED_PARAMETERS) {
      if (searchParams.has(key)) {
        utmParameters[key as keyof UtmData] = searchParams.get(key) as string;
      }
    }
    sessionStorage.setItem(UTM_DATA_SESSION_STORAGE_KEY, JSON.stringify(utmParameters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
