import React from 'react';

import PageTitle from 'components/PageTitle/PageTitle';
import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import {
  StyledDetailedHeaderSubtitle as CreatedDate,
  StyledDetailedHeaderTitle as Title,
  StyledDetailsHeader as Header,
  StyledDetailsHeaderContent as Content,
} from 'modules/InquiryDetails/InquiryDetailsHeader/StyledDetailsHeader';
import withInquiryHeaderInfo from 'modules/InquiryDetails/InquiryDetailsHeader/withInquiryHeaderInfo';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import useParamsSafe from 'shared/hooks/useParamsSafe';
import { LinkComponent } from 'theme/components/Link';
import { formatDateWithTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { userIsCustomer } from 'utils/user/conditionals';
interface Props {
  id: string;
  companyName: string;
  dateCreated?: Date;
  children?: React.ReactNode;
}

const InquiryDetailsHeader = ({ id, companyName, dateCreated, children }: Props) => {
  const t = useTranslations();
  const { selectedInquiryType } = useFormConfig();
  const { id: inquiryId } = useParamsSafe(['id']);

  const companyLinkHref = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: paths.operation.companies.overview(id),
    [InquiryType.cbBank]: paths.operation.inquiryDetails.companyDetails.associatedPersons(
      inquiryId,
      id,
    ),
  });
  const isCustomer = userIsCustomer();
  const isDefaultInquiry = selectedInquiryType === InquiryType.default;

  const LinkComp = (
    <LinkComponent variant="secondary" href={companyLinkHref}>
      {companyName}
    </LinkComponent>
  );

  return (
    <Header>
      <PageTitle title={t('pages.inquiryDetails.heading')} />
      <Content>
        <Title>{!isCustomer && !isDefaultInquiry ? LinkComp : companyName}</Title>
        {dateCreated && (
          <CreatedDate>
            {t('pages.inquiryDetails.header.from')} {formatDateWithTime(dateCreated)}
          </CreatedDate>
        )}
      </Content>
      {children}
    </Header>
  );
};

export default withInquiryHeaderInfo(InquiryDetailsHeader);
