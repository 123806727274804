import { cbBankFields } from 'cbbank/inquiry/fields';
import { LabelsMap, translations } from 'new/form/common/types';

const { iban, bic, bankName } = cbBankFields.personalDataPage.bankAccountSection;

const fieldTranslations =
  translations.inquiryType.cbBank.pages.personalData.sections.bankAccount.fields;

export const bankAccountSectionLabelsMap: LabelsMap = {
  [iban]: fieldTranslations.iban.caption,
  [bic]: fieldTranslations.bic.caption,
  [bankName]: fieldTranslations.bankName.caption,
};
