import * as React from 'react';

import { Center, Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Asset } from 'components/Asset';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { translations } from 'new/form/common/types';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getInquirySubStatusSelector } from 'store/inquiryDetails/selectors';
import { HeadingComponent } from 'theme/components/Heading';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';
import { userIsCustomer } from 'utils/user/conditionals';

import { ProgressSectionType } from './types';
import { mmvBusinessConstraints } from '../../../../mmv/utilities/businessConstraints';

type ProgressSuccessProps = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
};

export const ProgressSuccess = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
}: ProgressSuccessProps) => {
  const t = useTranslations();
  const {
    successAll,
    successBfss,
    successHausbank,
    successDescription,
    successDescriptionBfss,
    successDescriptionLeasePlan,
    successCompletedDescription,
    successDescriptionDzb,
    successDescriptionCustomerDzb,
  } = translations.pages.inquiryDetails.dashboard.summary;
  const isCustomer = userIsCustomer();
  const subStatus = useSelector(getInquirySubStatusSelector);

  const title = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: isCustomer ? t(successAll) : t(successBfss),
    [InquiryType.hausbank]: isCustomer ? t(successHausbank) : t(successAll),
    default: t(successAll),
  });

  const description = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: isCustomer ? t(successDescription) : t(successDescriptionBfss),
    [InquiryType.leaseplan]: t(successDescriptionLeasePlan),
    [InquiryType.mmv]:
      subStatus && mmvBusinessConstraints.isCompleted(subStatus)
        ? t(successCompletedDescription)
        : t(successDescription),
    [InquiryType.dzb]: isCustomer ? t(successDescriptionCustomerDzb) : t(successDescriptionDzb),
    default: t(successDescription),
  });

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent
            as="h4"
            variant="secondary"
            mb={2}
            color="brand.default"
            data-testid={'All-Done'}
          >
            {title}
          </HeadingComponent>
          <TextComponent data-testid={'All-Done-Text'}>{description}</TextComponent>
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset type="dashboard" value={ProgressSectionType.SUCCESS} htmlHeight="200" alt="" />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
