import { InitialFormStateType } from 'new/form/model';

import { CbBankFieldTypes } from '../fields';
import { cbBankSteps } from '../steps';

export const cbBankInitialFormState: InitialFormStateType<CbBankFieldTypes> = {
  currentStep: cbBankSteps.financingNeedPage,
  inquiryMode: 'create',
  pageValidations: {
    financingNeedPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        factoringNeedSection: false,
        businessDetailsSection: false,
      },
    },
    companyDataPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        companySearchSection: false,
        companyDetailsCompletionSection: false,
        complianceSection: false,
      },
    },
    offerPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        contractLaneSection: false,
      },
    },
    personalDataPage: {
      progress: {
        numberOfValidSections: 0,
        numberOfSections: 0,
        isSectionValid: false,
      },
      validations: {
        userDataSection: false,
        legalRepresentativesSection: false,
        beneficialOwnersSection: false,
        bankAccountSection: false,
      },
    },
  },
};
