import { bankAccountSectionValidationMap } from './sections/bankAccount/validations';
import { beneficialOwnersSectionValidationMap } from './sections/beneficialOwners/validations';
import { legalRepresentativesSectionValidationMap } from './sections/legalRepresentatives/validations';
import { userDataSectionValidationMap } from './sections/userData/validations';

export const cbBankPersonalDataFieldValidation = {
  ...userDataSectionValidationMap,
  ...legalRepresentativesSectionValidationMap,
  ...beneficialOwnersSectionValidationMap,
  ...bankAccountSectionValidationMap,
};
