import * as React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { SummaryPageField } from 'new/summary/hooks/useBuildFieldsForSummary';

type Props = {
  summaryPageField: SummaryPageField;
};
export const SummaryField = ({ summaryPageField }: Props) => {
  const textDisplay = summaryPageField.render ? (
    summaryPageField.render(summaryPageField.text)
  ) : (
    <Box data-testId={summaryPageField.testId} color={'text.primary'}>
      {summaryPageField.text}
    </Box>
  );

  return (
    <Flex flexDirection={'column'} gap={3}>
      <Box color={'text.tertiary'}>{summaryPageField.caption}</Box>
      {textDisplay}
    </Flex>
  );
};
