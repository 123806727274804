import { Route } from 'utils/paths';

const endpoints = {
  PARTNERS: {
    SETTINGS: new Route('/partners/:id'),
    INQUIRY_SETTINGS: new Route('/partners/:id/process_configuration'),
    UNITS: new Route('/partners/:id/organizational_units'),
    DATA_LIST: new Route('/partners/:id/data_lists/:listName'),
  },
  INQUIRIES: {
    DASHBOARD_ACTIONS: new Route('/inquiries/:id/dashboard_actions'),
    LIST: new Route('/inquiries'),
    DETAILS: new Route('/inquiries/:id'),
    SIGNEES: new Route('/inquiries/:id/signees'),
    FILES: {
      LIST: new Route('inquiries/:id/files'),
      EXTERNAL: new Route('inquiries/:id/files/external_services'),
      ARCHIVE: new Route('inquiries/:id/files/archive_all'),
    },
    PRIVATE_FILES: {
      LIST: new Route('inquiries/:id/private_files'),
    },
    ASSESSMENT_FILES: {
      LIST: new Route('inquiries/specialized/profi/:id/process_specific_files/assessment'),
    },
    PROCESS_SPECIFIC_UPLOADED_FILES: {
      LIST: new Route('inquiries/:id/process_specific_uploaded_files'),
    },
    FORWARD: {
      COMPEON: new Route('/inquiries/:id/forward_to_compeon'),
      COMPEON_ABORT: new Route('/inquiries/:id/abort_forward_to_compeon'),
      BANK_ADVISOR: new Route('/inquiries/:id/forward_to_bank_advisor'),
      INQUIRY_MANAGER: new Route('/inquiries/:id/forward_to_inquiry_manager'),
      CUSTOMER: new Route('/inquiries/:id/forward_to_customer'),
    },
    SPECIALIZED: {
      DETAILS: new Route('inquiries/specialized/:inquiryId'),
      TYPE: new Route('inquiries/specialized/:inquiryType'),
      PARTIAL: new Route('inquiries/specialized/:inquiryType/partial'),
      FINALIZE: new Route('inquiries/specialized/:inquiryType/:inquiryId/finalize'),
      FINAL_INFO: new Route('inquiries/specialized/:inquiryType/:inquiryId/final_info'),
      UPDATE: new Route('inquiries/specialized/:inquiryType/:inquiryId'),
      UPDATE_LEGAL_REPRESENTATIVES: new Route(
        'inquiries/specialized/big_picture/:inquiryId/update_legal_representatives',
      ),
      MARKET_RESEARCH: new Route('inquiries/specialized/profi/:inquiryId/market_data'),
      DOWNLOAD_PROPERTY_PROFILE_PDF: new Route(
        'inquiries/specialized/profi/:inquiryId/profitability/quick_check_pdf',
      ),
      PLANNING_EVALUATION_PROFITABILITY: new Route(
        'inquiries/specialized/profi/:inquiryId/profitability',
      ),
      OBJECT_ADDRESS: new Route('inquiries/specialized/profi/:inquiryId/object_address'),
      UPLOAD_OBJECT_IMAGES: new Route(
        'inquiries/specialized/profi/:inquiryId/profitability/object_images',
      ),
      DELETE_OBJECT_IMAGES: new Route(
        'inquiries/specialized/profi/:inquiryId/profitability/object_images/:fileId',
      ),
      BFS: {
        CANCEL: new Route('inquiries/specialized/bfs/:id/archive_with_referral'),
      },
      DZB: {
        ASSOCIATED_PEOPLE: {
          APPROVAL: new Route('inquiries/specialized/dzb/:id/associated_people_approval'),
        },
        LEGAL_REPRESENTATIVES: {
          CREATE: new Route('inquiries/specialized/dzb/:id/legal_representatives'),
          DELETE: new Route(
            'inquiries/specialized/dzb/:id/legal_representatives/:legalRepresentativeId',
          ),
          PATCH: new Route(
            'inquiries/specialized/dzb/:id/legal_representatives/:legalRepresentativeId',
          ),
        },
        BENEFICIAL_OWNERS: {
          CREATE: new Route('inquiries/specialized/dzb/:id/beneficiary_owners'),
          DELETE: new Route('inquiries/specialized/dzb/:id/beneficiary_owners/:beneficialOwnerId'),
          PATCH: new Route('inquiries/specialized/dzb/:id/beneficiary_owners/:beneficialOwnerId'),
        },
        FILES: {
          UPLOAD_WELCOME_PACKAGE: new Route(
            'inquiries/specialized/dzb/:id/process_specific_files/welcome_package',
          ),
        },
      },
      MMV: {
        OBJECT_CONFIRMATION: {
          DATA: new Route('inquiries/specialized/mmv/:inquiryId/object_confirmation/data'),
          FILLED_DOCUMENT: new Route(
            'inquiries/specialized/mmv/:inquiryId/object_confirmation/filled_document',
          ),
          FINISH: new Route('inquiries/specialized/mmv/:inquiryId/object_confirmation/finish'),
        },
      },
      HAUSBANK: {
        CONTRACTS: {
          UPLOAD: new Route(
            'inquiries/specialized/hausbank/:inquiryId/process_specific_files/contract',
          ),
        },
      },
      CB_BANK: {
        CALCULATE_FACTORING_LINE: new Route(
          'inquiries/specialized/cb_bank/calculate_factoring_line',
        ),
        CALCULATE_INDICATIVE_CONDITIONS: new Route(
          'inquiries/specialized/cb_bank/:id/indicative_conditions/calculate',
        ),
        GET_BENEFICIARY_OWNERS: new Route(
          'inquiries/specialized/cb_bank/:inquiryId/beneficiary_owners',
        ),
        GET_LEGAL_REPRESENTATIVES: new Route(
          'inquiries/specialized/cb_bank/:inquiryId/legal_representatives',
        ),
        INDICATIVE_CONDITIONS_SUGGESTION: new Route(
          'inquiries/specialized/cb_bank/:id/indicative_conditions/suggestion',
        ),
        INDICATIVE_CONDITIONS: new Route('inquiries/specialized/cb_bank/:id/indicative_conditions'),
        LEGAL_REPRESENTATIVES: {
          CREATE: new Route('inquiries/specialized/cb_bank/:id/legal_representatives'),
          DELETE: new Route(
            'inquiries/specialized/cb_bank/:id/legal_representatives/:legalRepresentativeId',
          ),
          PATCH: new Route(
            'inquiries/specialized/cb_bank/:id/legal_representatives/:legalRepresentativeId',
          ),
        },
        BENEFICIAL_OWNERS: {
          CREATE: new Route('inquiries/specialized/cb_bank/:id/beneficiary_owners'),
          DELETE: new Route(
            'inquiries/specialized/cb_bank/:id/beneficiary_owners/:beneficialOwnerId',
          ),
          PATCH: new Route(
            'inquiries/specialized/cb_bank/:id/beneficiary_owners/:beneficialOwnerId',
          ),
        },
        ASSOCIATED_PEOPLE: {
          APPROVAL: new Route('inquiries/specialized/cb_bank/:id/associated_people_approval'),
        },
        REPRESENTATIVES_SIGNATURES_APPROVAL: new Route(
          'inquiries/specialized/cb_bank/:id/signature_process/representatives_signatures_approval',
        ),
        CONTRACTS: {
          UPLOAD: new Route('inquiries/specialized/cb_bank/:id/process_specific_files/contracts'),
          GENERATE: new Route(
            'inquiries/specialized/cb_bank/:id/process_specific_files/contracts/generate',
          ),
          CONFIRM: new Route('inquiries/specialized/cb_bank/:id/signature_process/start'),
        },
      },
    },
    DRAFTS: {
      LIST: new Route('/inquiries/drafts'),
      DETAILS: new Route('/inquiries/drafts/:draftId'),
    },
    CORONA_CALC: new Route('/inquiries/corona_package_calculator'),
    STATUSES: new Route('/inquiries/statuses'),
    INQUIRY_MANAGERS: new Route('/inquiry_managers'),
    REAL_ESTATE_EXPERTS: {
      LIST: new Route('/real_estate_experts'),
      ASSIGN_REE_TO_INQUIRY: new Route('/inquiries/:inquiryId/forward_to_real_estate_expert'),
    },
    INTEREST_RATE: new Route('inquiries/request_interest_rate_and_instalment'),
    INSURANCE_OFFER: new Route('/inquiries/:id/request_insurance_offer'),
    REQUESTED_DOCS: new Route('inquiries/:id/file_upload_requests'),
    COMPEON_DOCUMENTS: new Route('inquiries/:id'),
    COMPEON_DOCUMENT_DOWNLOAD: new Route('company_documents/:id/download'),
    SPECIALIZED_PROCESS: new Route('inquiries/:id/specialized_process'),
    ARCHIVE: new Route('/inquiries/:id/archive'),
    PROCESS_LOG: new Route('/inquiries/:id/logged_activities'),
    COMMENTS: new Route('/inquiries/:id/comments'),
    BANK_OFFERS: new Route('/inquiries/:id/offers'),
    CHANGELOG: new Route('inquiries/:id/changelogs'),
    NON_FASTLANE: new Route('inquiries/:id/move_to_non_fastlane'),
    INDICATIVE_OFFER: new Route('inquiries/:id/indicative_conditions'),
    TRANSPARENCY_REGISTER_SEARCH_RESULT: new Route(
      'inquiries/:id/transparency_register/select_search_result',
    ),
    DIGITAL_PROCESSING: new Route('inquiries/:id/set_digital_processing'),
    CREFO_REPORT: new Route('inquiries/:id/crefo/request_report'),
    KYC_REQUEST: new Route('/inquiries/:id/kycnow/request_report'),
    SKIP_KYC: new Route('/inquiries/:id/kycnow/skip_request'),
    INVITE_CUSTOMER_REQUEST: new Route('/customers/send_invitation'),
    INCONSISTENCY_REPORT: new Route('inquiries/:id/transparency_register/inconsistency_report'),
    INDICATIVE_OFFER_CALC: new Route('/inquiries/:id/indicative_offer_calculator'),
    CREATE_CONTRACT: new Route('/inquiries/:id/offers/create_contract '),
  },
  USERS: {
    ME: new Route('/users/me'),
    CONFIRM: new Route('/users/confirm_account'),
    PASSWORD_RESET: new Route('/users/reset_password'),
    PASSWORD_RESET_REQUEST: new Route('/users/request_password_reset'),
    PASSWORD_RESET_TOKEN_VALIDATE: new Route('/users/validate_token'),
    EXCHANGE_SSO: new Route('/users/exchange_sso_token'),
    EMAIL_AVAILABILITY: new Route('/users/check_email'),
    INVITATION_ACCEPT: new Route('users/accept_invitation'),
  },
  OP_USERS: {
    LIST: new Route('/portal_users'),
    PROFILE: new Route('/portal_users/profile'),
  },
  CUSTOMERS: {
    LIST: new Route('/customers'),
    DATA: new Route('/customers/by_email'),
    ARCHIVE: new Route('/customers/:id/clear_data'),
    COMPANIES: new Route('/customers/:id/companies'),
    CALL_REQUEST: new Route('/customers/callback_request'),
  },
  COMPANIES: {
    LIST: new Route('/companies'),
    CORONA_PROGRAM: new Route('/companies/corona_kfw_program'),
    LAST_CUSTOMER: new Route('/companies/:id/last_end_customer'),
    RISK: new Route('/companies/:id/risk_analysis'),
    SPECIALIZED: {
      LIST: new Route('/companies/specialized'),
      DETAILS: new Route('/companies/specialized/:id'),
      ASSOCIATES: new Route('/companies/specialized/:id/associated_people'),
      ASSOCIATES_MERGE: new Route('/companies/specialized/:id/associated_people/merge'),
    },
    KYC_COMPANIES: new Route('/companies/:id/kyc_companies'),
    UPDATE_KYC_COMPANY_DATA: new Route('/companies/:kyc_id/update_kyc'),
    KPIS: new Route('/companies/:id/kpis'),
  },
  FILE_UPLOAD_REQUESTS: {
    PREDEFINED_REQUESTS: new Route('/file_upload_requests/predefined_requests'),
  },
  FILES: {
    LIST: new Route('/files'),
    DETAILS: new Route('/files/:id'),
    DOWNLOAD: new Route('/files/:id/download'),
    STATUS: new Route('/files/:id/status'),
    MARK_FOR_DIGITIZATION: new Route('/files/:id/mark_for_digitization'),
    MARK_FOR_KPI_CALCULATION: new Route('/files/:id/mark_for_kpi_calculation'),
    CLASSIFICATION: new Route('/files/:id/classification'),
  },
  PRIVATE_FILES: {
    DETAILS: new Route('/private_files/:id'),
    DOWNLOAD: new Route('/private_files/:id/download'),
    CLASSIFICATION: new Route('/private_files/:id/classification'),
    ARCHIVE: new Route('/private_files/:id/archive'),
  },
  ASSESSMENT_FILES: {
    DETAILS: new Route('/process_specific_files/:id'),
    DOWNLOAD: new Route('/process_specific_files/:id/download'),
    ARCHIVE: new Route('/process_specific_files/:id/archive'),
    DELETE: new Route('/process_specific_files/:id'),
  },
  OFFERS: {
    ACCEPT: new Route('/offers/:id/accept'),
    INTEREST: new Route('/offers/:id/signal_interest'),
    SIGNING_PROCESS: new Route('/offers/:id/start_document_signing_process'),
  },
  SMS_TOKEN: {
    LIST: new Route('/sms_tokens'),
    EXCHANGE: new Route('/sms_tokens/exchange'),
  },
  BANKS: {
    BANK_INFO: new Route('/banks/bank_info'),
  },
  LEADS: new Route('/leads'),
  BANK_ADVISORS: new Route('/bank_advisors'),
  CHANGELOG: new Route('/changelogs/:id'),
} as const;

export default endpoints;
