import { useFormState } from 'react-final-form';

import { CbBankFieldTypes } from 'cbbank/inquiry/fields';
import { cbBankSteps } from 'cbbank/inquiry/steps';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { translations } from 'new/form/common/types';
import { BeforeNextStepActionFunction, LoadingModalControls } from 'new/form/model';
import { useInquiryProcessConfig } from 'new/form/state/inquiryProcessConfig/hooks';
import { createPartialSpecializedInquiryAction } from 'pages/inquiryFlow/Rentenbank/useSendSpecializedInquiry';
import { getExpirationDate, setToken } from 'utils/auth';
import useDispatchApiCall, { dispatchOptions } from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { mapCbBankCompanyDetailsCompletion } from './steps/companyData/sections/companyDetailsCompletion/dataMapper';
import { mapCbBankCompanySearch } from './steps/companyData/sections/companySearch/dataMapper';
import { mapCbBankBusinessDetails } from './steps/financingNeed/sections/businessDetails/dataMapper';
import { mapCbBankFactoringNeed } from './steps/financingNeed/sections/factoringNeed/dataMapper';

export const useCbBankBeforeNextStep = () => {
  const t = useTranslations();
  const { title, description } = translations.inquiryType.cbBank.pages.companyData.loadingModal;
  const { makeCall } = useDispatchApiCall(dispatchOptions(t(translations.errors.actionFailed)));
  const { values } = useFormState<CbBankFieldTypes>();
  const {
    state: { currentStep },
  } = useInquiryProcessConfig();

  const companyDataStepActionFunction: BeforeNextStepActionFunction = async (actionProps: {
    loadingModalControls: LoadingModalControls;
    moveToNextStep: () => void;
  }) => {
    const { loadingModalControls, moveToNextStep } = actionProps;
    loadingModalControls.setLoadingModalProps({
      title: t(title),
      description: t(description),
    });
    const {
      financingNeedPage: { factoringNeedSection, businessDetailsSection },
      companyDataPage: { companySearchSection, companyDetailsCompletionSection },
    } = values;

    // Create the payload for the partial inquiry
    const apiData = {
      ...mapCbBankFactoringNeed(factoringNeedSection),
      ...mapCbBankBusinessDetails(businessDetailsSection),
      company_data: {
        ...mapCbBankCompanySearch(companySearchSection),
        ...mapCbBankCompanyDetailsCompletion(companyDetailsCompletionSection),
      },
    };

    // Create the reduxThunk action
    const action = createPartialSpecializedInquiryAction(apiData, InquiryType.cbBank);
    loadingModalControls.onLoadingModalOpen();
    const response = await makeCall(action);
    loadingModalControls.onLoadingModalClose();
    if (!response.error) {
      const headers = response?.payload?.headers;
      const token = headers['temp-access-token'];
      const refreshToken = headers['temp-refresh-token'];
      const expiresIn = headers['temp-expires-in'];
      const createdAt = headers['temp-created-at'];
      const expiresAt = getExpirationDate(Number(createdAt), Number(expiresIn));

      setToken({ token, refreshToken, expiresAt, scope: 'customer' });
      moveToNextStep();
    }
  };

  switch (currentStep) {
    case cbBankSteps.companyDataPage:
      return companyDataStepActionFunction;
    default:
      return undefined;
  }
};
