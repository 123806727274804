import {
  ALL_USERS,
  USER_ANONYMOUS,
  USER_ROLE_BANK_ADVISOR,
  USER_ROLE_CALL_CENTER_AGENT,
  USER_ROLE_CUSTOMER,
  USER_ROLE_INQUIRY_MANAGER,
  USER_ROLE_REAL_ESTATE_EXPERT,
  UserRole,
} from 'constants/user/userRoles';

import { InquiryType } from '../../modules/Inquiry/Inquiry.type';

export type UserRoleObject = { [InquiryType.default]: UserRole[] } & Partial<
  Record<Exclude<InquiryType, InquiryType.default>, UserRole[]>
>;

interface PermissionsTree extends Record<string, UserRole[] | PermissionsTree | UserRoleObject> {}

const PERMISSIONS = {
  ARCHIVE_USER: {
    [InquiryType.default]: [USER_ROLE_INQUIRY_MANAGER],
    [InquiryType.cbBank]: [USER_ROLE_INQUIRY_MANAGER, USER_ROLE_BANK_ADVISOR],
  },
  COMPANY_DETAILS: {
    DISPLAY: [USER_ROLE_BANK_ADVISOR, USER_ROLE_INQUIRY_MANAGER],
  },
  INQUIRY: {
    DETAILS: {
      DASHBOARD: {
        SUMMARY: [USER_ROLE_CALL_CENTER_AGENT, USER_ROLE_BANK_ADVISOR, USER_ROLE_INQUIRY_MANAGER],
        ACTIONS: {
          DISPLAY: [USER_ROLE_CALL_CENTER_AGENT, USER_ROLE_BANK_ADVISOR],
        },
        CLOSE_INQUIRY: {
          DISPLAY: {
            [InquiryType.default]: [USER_ROLE_INQUIRY_MANAGER],
            [InquiryType.cbBank]: [USER_ROLE_BANK_ADVISOR, USER_ROLE_INQUIRY_MANAGER],
          },
        },
        FORWARD_INQUIRY: {
          DISPLAY: [USER_ROLE_CALL_CENTER_AGENT, USER_ROLE_BANK_ADVISOR, USER_ROLE_INQUIRY_MANAGER],
        },
        COMMUNICATION: {
          DISPLAY: [
            USER_ROLE_CALL_CENTER_AGENT,
            USER_ROLE_BANK_ADVISOR,
            USER_ROLE_INQUIRY_MANAGER,
            USER_ROLE_REAL_ESTATE_EXPERT,
          ],
          PROCESS_LOG: [
            USER_ROLE_CALL_CENTER_AGENT,
            USER_ROLE_BANK_ADVISOR,
            USER_ROLE_INQUIRY_MANAGER,
            USER_ROLE_REAL_ESTATE_EXPERT,
          ],
          INTERNAL_COMMENTS: {
            [InquiryType.default]: [
              USER_ROLE_CALL_CENTER_AGENT,
              USER_ROLE_BANK_ADVISOR,
              USER_ROLE_INQUIRY_MANAGER,
              USER_ROLE_REAL_ESTATE_EXPERT,
            ],
            [InquiryType.mmv]: [],
          },
        },
      },
      DOCUMENT_EXCHANGE: {
        CREATE_DOCUMENT_REQUEST: {
          [InquiryType.default]: [USER_ROLE_INQUIRY_MANAGER, USER_ROLE_BANK_ADVISOR],
          [InquiryType.mmv]: [],
        },
      },
      OFFERS: [USER_ROLE_CUSTOMER],
      CHANGELOG: [USER_ROLE_INQUIRY_MANAGER, USER_ROLE_BANK_ADVISOR],
    },
    EDIT: [
      USER_ROLE_CUSTOMER,
      USER_ROLE_INQUIRY_MANAGER,
      USER_ROLE_BANK_ADVISOR,
      USER_ROLE_REAL_ESTATE_EXPERT,
    ],
    EDIT_DRAFT: [USER_ROLE_CUSTOMER],
    PERFORM_WELCOME_CALL: [USER_ROLE_CALL_CENTER_AGENT],
    FORWARD_TO_INQUIRY_MANAGER: {
      [InquiryType.default]: [USER_ROLE_CALL_CENTER_AGENT],
      [InquiryType.mmv]: [],
    },
    FORWARD_TO_BANK_ADVISOR: [USER_ROLE_INQUIRY_MANAGER],
    SUBMIT_OFFER: [USER_ROLE_BANK_ADVISOR],
    FORWARD_TO_CUSTOMER: [USER_ROLE_BANK_ADVISOR, USER_ROLE_INQUIRY_MANAGER],
    ARCHIVE: {
      [InquiryType.default]: [USER_ROLE_INQUIRY_MANAGER],
      [InquiryType.cbBank]: [USER_ROLE_BANK_ADVISOR, USER_ROLE_INQUIRY_MANAGER],
    },
    CANCEL: {
      [InquiryType.default]: [USER_ROLE_BANK_ADVISOR, USER_ROLE_INQUIRY_MANAGER],
    },
    SAVE_DRAFT: [USER_ROLE_CUSTOMER],
    REMOVE_DRAFT: [USER_ROLE_CUSTOMER],
    CREATE: {
      // these do not allow creating a new inquiry
      [InquiryType.mmv]: [],
      [InquiryType.dzb]: [],
      [InquiryType.leaseplan]: [],
      // allow creating a new inquiry for unauthorized users, call center agents, inquiry managers, real estate experts
      [InquiryType.bfsService]: [
        USER_ANONYMOUS,
        USER_ROLE_CALL_CENTER_AGENT,
        USER_ROLE_INQUIRY_MANAGER,
        USER_ROLE_REAL_ESTATE_EXPERT,
      ],
      [InquiryType.onlinefactoring]: ALL_USERS,
      [InquiryType.profiMittweida]: ALL_USERS,
      [InquiryType.profiRLL]: ALL_USERS,
      [InquiryType.bfs]: ALL_USERS,
      [InquiryType.hausbank]: ALL_USERS,
      [InquiryType.default]: [USER_ANONYMOUS, USER_ROLE_CUSTOMER],
    },
  },
} satisfies PermissionsTree;

export default PERMISSIONS;
