import React from 'react';

import { Box, Center, Divider, Flex } from '@chakra-ui/react';
import { Form } from 'react-final-form';

import FormSection from 'components/FormSection';
import FormSectionLayout from 'components/FormSection/FormSectionLayout';
import { Container } from 'components/PortalPage/styles';
import { UPLOADING_STATE } from 'components/UploadBlock/NewFileRequestBlock/FileRequest.service';
import UploadDropzone from 'components/UploadBlock/UploadIdleState/UploadDropzone';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import CheckmarkIcon from 'theme/components/Icon/CheckmarkIcon';
import CloseIcon from 'theme/components/Icon/CloseIcon';
import UploadIcon from 'theme/components/Icon/UploadIcon';
import { TableComponent } from 'theme/components/Table';
import { TextComponent } from 'theme/components/Text';
import { useScrollToTop } from 'utils/hooks/useScrollToTop';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useDeferredUploadOffer, useUploadOffer, useUploadOfferUtils } from './useUploadOffer';

export const UploadOffer = () => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.uploadOffer.uploadFiles');

  const { deferred, submitOffer } = useUploadOfferUtils();
  useScrollToTop();

  const useUploadOfferHook = deferred
    ? useDeferredUploadOffer.bind(null, submitOffer)
    : useUploadOffer;
  const hookProps = useUploadOfferHook();

  // Pick the attributes that are not returned by both hooks and add them as optional
  type UseUploadOfferHookReturnType = Pick<
    Partial<ReturnType<typeof useUploadOffer>>,
    | 'createContract'
    | 'handleGenerateContract'
    | 'onFileSelected'
    | 'onFilesSelected'
    | 'disableGenerateContract'
    | 'generateContractLoading'
    | 'submitOffersLoading'
  > &
    ReturnType<typeof useDeferredUploadOffer>;

  const {
    files,
    handleSetFiles,
    submitDocuments,
    submitOffersLoading,
    uploadingState,
    uploadProgress,
    errorMsg,
    setIsDropping,
    isDropping,
    goBackToDashboard,
    multipleFiles,
    createContract,
    handleGenerateContract,
    onFileSelected,
    onFilesSelected,
    disableGenerateContract,
    generateContractLoading,
  } = hookProps as UseUploadOfferHookReturnType;

  const colHeaders = [t('table.uploadedDocuments'), t('table.actions')];
  const onFileDropped = deferred
    ? handleSetFiles
    : multipleFiles
    ? onFilesSelected!
    : onFileSelected!;

  const uploadButtonDisabled = !files.length || uploadingState !== UPLOADING_STATE.IDLE;

  return (
    <Container>
      <Box pt="12" pb="20">
        <Box maxWidth="800px" pl={[null, null, null, '20rem']}>
          <HeadingComponent as="h2" variant="secondary" mb={3}>
            {t('title')}
          </HeadingComponent>
          <TextComponent color="text.tertiary">{t('description')}</TextComponent>
        </Box>

        <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />

        <Form
          onSubmit={submitDocuments}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {createContract && (
                <FormSection title={t('generateContract.title')}>
                  <TextComponent color="text.tertiary">
                    {t('generateContract.description')}
                  </TextComponent>
                  <Flex justify="flex-start" mt={12}>
                    <ButtonComponent
                      onClick={handleGenerateContract}
                      disabled={disableGenerateContract}
                      testId="generateContractButton"
                      isLoading={generateContractLoading}
                    >
                      {t('generateContract.submit')}
                    </ButtonComponent>
                  </Flex>
                </FormSection>
              )}

              <FormSectionLayout title={t('sectionTitle')}>
                <Box mb={12}>
                  <UploadDropzone
                    onFileDropping={setIsDropping}
                    onFileDropped={onFileDropped}
                    disabled={uploadingState !== UPLOADING_STATE.IDLE}
                    multipleFiles={multipleFiles}
                  >
                    <Center
                      flexDirection="column"
                      minHeight="258px"
                      p={8}
                      border="2px dashed"
                      borderColor={isDropping ? 'brand.default' : 'border.darkGrey'}
                      borderRadius="default"
                      bgColor={isDropping ? 'brand.default_L93' : 'background.white'}
                    >
                      {uploadingState === UPLOADING_STATE.SCANNING && <Box>{t('scanning')}</Box>}

                      {uploadingState === UPLOADING_STATE.UPLOADING && <Box>{uploadProgress}%</Box>}

                      {uploadingState === UPLOADING_STATE.FINISHED && (
                        <Box>
                          <CheckmarkIcon boxSize={16} color="icon.ok" />
                        </Box>
                      )}
                      {uploadingState === UPLOADING_STATE.ERROR && <Box>{errorMsg}</Box>}

                      {uploadingState === UPLOADING_STATE.IDLE && (
                        <>
                          <UploadIcon boxSize={12} mb={4} color="brand.default" />
                          <TextComponent as="span" mb={3}>
                            {t('uploadRequest')}
                          </TextComponent>
                          <TextComponent as="span" mb={4} color="text.tertiary">
                            {t('uploadAlternative')}
                          </TextComponent>
                          <ButtonComponent testId="searchDocumentsButton">
                            {t('searchDocuments')}
                          </ButtonComponent>
                        </>
                      )}
                    </Center>
                  </UploadDropzone>
                </Box>

                <Box>
                  <TableComponent colHeaders={colHeaders} tbodyRows={files} hasActions />
                </Box>
              </FormSectionLayout>

              <Flex justify="flex-end" mt={12}>
                <ButtonComponent
                  leftIcon={<CloseIcon boxSize={6} display="block" />}
                  mr={4}
                  onClick={goBackToDashboard}
                  variant="tertiary"
                  data-testid={'After-Contract-Upload-Buttons-Cancel'}
                >
                  {t('cancel')}
                </ButtonComponent>
                <ButtonComponent
                  leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
                  type="submit"
                  disabled={uploadButtonDisabled}
                  data-testid={'After-Contract-Upload-Buttons-Submit'}
                  isLoading={submitOffersLoading}
                >
                  {t('submit')}
                </ButtonComponent>
              </Flex>
            </form>
          )}
        />
      </Box>
    </Container>
  );
};
