import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FieldTypes } from 'new/form/common/types';
import { ConditionFunction, ConditionsType } from 'new/form/model';
import { createUseConditions } from 'shared/inquiry/createUseConditions';
import { parseStringBoolean } from 'utils/helpers';

import { cbBankFields, CbBankFieldTypes } from '../fields';

const {
  companyDataPage: {
    companyDetailsCompletionSection: { positiveAnnualResult, positiveEquity },
  },
} = cbBankFields;

export const showBeneficialOwnersAddButton: ConditionFunction<CbBankFieldTypes> = ({ values }) => {
  return (
    parseStringBoolean(values.personalDataPage.beneficialOwnersSection?.allInformationAvailable) ===
    true
  );
};

export const showBeneficialOwnersSection = ({
  values,
}: {
  values: FieldTypes<InquiryType.cbBank>;
}) => {
  const allInformationAvailable =
    values.personalDataPage.beneficialOwnersSection?.allInformationAvailable;
  if (allInformationAvailable) {
    return parseStringBoolean(allInformationAvailable);
  }
};

const isSubjectToAccounting = ({ values }: { values: FieldTypes<InquiryType.cbBank> }) => {
  return parseStringBoolean(
    values.companyDataPage.companyDetailsCompletionSection?.subjectToAccounting,
  );
};

export const cbBankConditions: ConditionsType<CbBankFieldTypes> = {
  showBeneficialOwnersAddButton,
  showBeneficialOwnersSection,
  [positiveAnnualResult]: isSubjectToAccounting,
  [positiveEquity]: isSubjectToAccounting,
};

export const useCbBankConditions = createUseConditions(cbBankConditions);
