import { UTM_DATA_SESSION_STORAGE_KEY } from './keys';

export const getParsedItemFromSessionStorage = (key: string) => {
  const item = sessionStorage.getItem(key);
  return item ? JSON.parse(item) : item;
};

export const saveObjectToSessionStorage = (key: string, data: any) =>
  sessionStorage.setItem(key, JSON.stringify(data));

export const removeObjectFromSessionStorage = (key: string) => sessionStorage.removeItem(key);

export const removeInnerPropertyFromSessionStorage = (mainKey: string, keyToBeRemoved: string) => {
  const data = getParsedItemFromSessionStorage(mainKey);
  if (Object.prototype.hasOwnProperty.call(data, keyToBeRemoved)) {
    delete data[keyToBeRemoved];
    sessionStorage.setItem(mainKey, JSON.stringify(data));
  }
};

export const clearSession = () => sessionStorage.clear();

export const getUTMData = () => {
  const utmData = JSON.parse(sessionStorage.getItem(UTM_DATA_SESSION_STORAGE_KEY) ?? '{}');
  return utmData;
};
