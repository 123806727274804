import React from 'react';

import { summaryFieldUnits as units } from 'new/form/formatters/units';
import { SummaryFieldsType } from 'new/form/model';
import { BadgeComponent } from 'theme/components/Badge';

import { CbBankFieldTypes } from '../fields';

export const cbBankSummaryFields: SummaryFieldsType<CbBankFieldTypes> = {
  financingNeedPage: {
    factoringNeedSection: {
      annualFactoringRelevantTurnover: {
        unit: units.currency2Decimals,
        showOnSummaryPage: true,
      },
      paymentTerm: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      factoringLine: {
        unit: units.currencyMax2Decimals,
        showOnSummaryPage: true,
        showEmpty: true,
      },
    },
    businessDetailsSection: {
      turnoverShareOfLargestClient: {
        unit: units.percentage,
        showOnSummaryPage: true,
      },
      centralisedSettlement: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      tradeCreditInsurance: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      downPaymentOrInstallments: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      assignedElsewhere: {
        unit: units.none,
        showOnSummaryPage: true,
      },
      customersAbroad: {
        unit: units.none,
        showOnSummaryPage: true,
      },
    },
  },
  companyDataPage: {
    companySearchSection: {
      crefoId: {
        showOnSummaryPage: false,
        unit: units.none,
      },
      selectedCompanyName: {
        showOnSummaryPage: false,
        unit: units.none,
      },
    },
    companyDetailsCompletionSection: {
      name: {
        showOnSummaryPage: true,
        unit: units.none,
        order: 1,
      },
      street: {
        showOnSummaryPage: true,
        unit: units.none,
        order: 2,
      },
      zipCode: {
        showOnSummaryPage: true,
        unit: units.none,
        order: 4,
      },
      city: {
        showOnSummaryPage: true,
        unit: units.none,
        order: 3,
      },
      legalForm: {
        showOnSummaryPage: true,
        unit: units.none,
      },
      industry: {
        showOnSummaryPage: true,
        unit: units.none,
      },
      olderThan2Years: {
        showOnSummaryPage: true,
        unit: units.none,
      },
      subjectToAccounting: {
        showOnSummaryPage: true,
        unit: units.none,
      },
      positiveEquity: {
        showOnSummaryPage: true,
        unit: units.none,
      },
      positiveAnnualResult: {
        showOnSummaryPage: true,
        unit: units.none,
      },
    },
    complianceSection: {
      termsOfService: {
        showOnSummaryPage: false,
        unit: units.none,
      },
      dataProtection: {
        showOnSummaryPage: false,
        unit: units.none,
      },
      factoringTerms: {
        showOnSummaryPage: false,
        unit: units.none,
      },
    },
  },
  offerPage: {
    contractLaneSection: {
      phoneNumber: {
        showOnSummaryPage: false,
        unit: units.none,
      },
      factoringFee: {
        showOnSummaryPage: true,
        unit: units.percentage,
        showEmpty: true,
      },
      factoringLine: {
        showOnSummaryPage: true,
        unit: units.currencyMax2Decimals,
        showEmpty: true,
      },
      payoutRatio: {
        showOnSummaryPage: true,
        unit: units.percentage,
        showEmpty: true,
      },
      pinNumber: {
        showOnSummaryPage: false,
        unit: units.none,
      },
    },
  },
  personalDataPage: {
    userDataSection: {
      salutation: {
        showOnSummaryPage: true,
        unit: units.none,
      },
      firstName: {
        showOnSummaryPage: true,
        unit: units.none,
      },
      lastName: {
        showOnSummaryPage: true,
        unit: units.none,
      },
      email: {
        showOnSummaryPage: true,
        unit: units.none,
      },
      phoneNumber: {
        showOnSummaryPage: true,
        unit: units.none,
      },
    },
    legalRepresentativesSection: {
      legalRepresentatives: {
        id: {
          showOnSummaryPage: false,
          unit: units.none,
        },
        salutation: {
          showOnSummaryPage: true,
          unit: units.none,
        },
        firstName: {
          showOnSummaryPage: true,
          unit: units.none,
        },
        lastName: {
          showOnSummaryPage: true,
          unit: units.none,
        },
        birthDate: {
          showOnSummaryPage: true,
          unit: units.date,
        },
        email: {
          showOnSummaryPage: true,
          unit: units.none,
        },
        phoneNumber: {
          showOnSummaryPage: true,
          unit: units.none,
        },
        soleSignatureAuthorized: {
          showOnSummaryPage: true,
          unit: units.none,
        },
        signingPriority: {
          showOnSummaryPage: true,
          unit: units.none,
          render: (text) => (
            <BadgeComponent variant="info" width="max-content">
              {text}
            </BadgeComponent>
          ),
        },
      },
    },
    beneficialOwnersSection: {
      allInformationAvailable: {
        showOnSummaryPage: true,
        unit: units.none,
      },
      beneficialOwners: {
        id: {
          showOnSummaryPage: false,
          unit: units.none,
        },
        firstName: {
          showOnSummaryPage: true,
          unit: units.none,
        },
        lastName: {
          showOnSummaryPage: true,
          unit: units.none,
        },
        nationality: {
          showOnSummaryPage: true,
          unit: units.none,
        },
        capitalOrVotingRightsPercentage: {
          showOnSummaryPage: true,
          unit: units.percentage,
        },
        placeOfResidence: {
          showOnSummaryPage: true,
          unit: units.none,
        },
        countryOfResidence: {
          showOnSummaryPage: true,
          unit: units.none,
        },
        birthPlace: {
          showOnSummaryPage: true,
          unit: units.none,
        },
        birthDate: {
          showOnSummaryPage: true,
          unit: units.date,
        },
      },
    },
    bankAccountSection: {
      iban: {
        showOnSummaryPage: true,
        unit: units.none,
        order: 1,
      },
      bic: {
        showOnSummaryPage: true,
        unit: units.none,
        order: 3,
      },
      bankName: {
        showOnSummaryPage: true,
        unit: units.none,
        order: 2,
      },
    },
  },
};
