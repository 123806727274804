import { cbBankFeToBeMapping } from 'cbbank/inquiry/config/feToBeMapping';
import { cbBankStepData } from 'cbbank/inquiry/config/stepData';
import { cbBankStepSequence } from 'cbbank/inquiry/config/stepSequence';
import { cbBankSummaryFields } from 'cbbank/inquiry/config/summaryFields';
import { CbBankFieldTypes, cbBankFields } from 'cbbank/inquiry/fields';
import { PortalConfig } from 'new/portals/state/portalConfigContext/types';

export const cbBankPortalConfig: PortalConfig<CbBankFieldTypes> = {
  stepSequence: cbBankStepSequence,
  stepData: cbBankStepData,
  summaryFields: cbBankSummaryFields,
  feToBeMappingKeys: cbBankFeToBeMapping,
  fields: cbBankFields,
};
