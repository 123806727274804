import { extendTheme } from '@chakra-ui/react';

import Accordion from './components/Accordion/styles/Accordion';
import Alert from './components/Alert/styles/Alert';
import AlertDialog from './components/AlertDialog/styles/AlertDialog';
import Badge from './components/Badge/styles/Badge';
import Button from './components/Button/styles/Button';
import Checkbox from './components/Checkbox/styles/Checkbox';
import CircularProgress from './components/CircularProgress/styles/CircularProgess';
import CustomerInquiryListHeading from './components/CustomerInquiryList/styles/CustomerInquiryListHeading';
import CustomerLogin from './components/CustomerLogin/styles/CustomerLogin';
import CustomerLoginHeading from './components/CustomerLogin/styles/CustomerLoginHeading';
import DescriptionList from './components/DescriptionList/styles/DescriptionList';
import Drawer from './components/Drawer/styles/Drawer';
import FieldWrapper from './components/FieldWrapper/styles/FieldWrapper';
import FinancialBalanceSheet from './components/FinancialInformations/FinancialBalanceSheet/styles/FinancialBalanceSheet';
import FinancialsGuV from './components/FinancialInformations/FinancialIncomeStatement/styles/FinancialsGuV';
import Heading from './components/Heading/styles/Heading';
import HorizontalSection from './components/HorizontalSection/styles/HorizontalSection';
import IconButton from './components/IconButton/styles/IconButton';
import KpiCard from './components/KpiCard/style/KpiCard';
import Link from './components/Link/styles/Link';
import Modal from './components/Modal/styles/Modal';
import Popover from './components/Popover/styles/Popover';
import PortalPage from './components/PortalPage/styles/PortalPage';
import ProgressSection from './components/ProgressSection/styles/ProgressSection';
import RadioButton from './components/RadioButton/styles/RadioButton';
import Table from './components/Table/styles/Table';
import Text from './components/Text/styles/Text';
import Tooltip from './components/Tooltip/styles/Tooltip';
import VerticalProgressBar from './components/VerticalProgressBar/styles/VerticalProgressBar';
import { hexToHSL, setLuminosity } from './functions';
import globalStyles from './globalStyles';
import {
  borderRadius as radii,
  boxShadow as shadows,
  spacing as space,
  textStyle as textStyles,
  transition as transitions,
  zIndex as zIndices,
} from './themeConstants';
import SummaryCard from '../mittweida/portals/operations/components/SummaryCards/styles/SummaryCard.styles';
import SummaryCards from '../mittweida/portals/operations/components/SummaryCards/styles/SummaryCards.styles';

export const WHITE = '#fff';
export const WHITE_GREY = '#f4f4f4';
export const LIGHTEST_GREY = '#e0e0e0';
export const LIGHTER_GREY = '#c0c0c0';
export const LIGHT_GREY = '#b0b0b0';
export const GREY = '#8d8d8d';
export const DARK_GREY = '#707070';
export const DARKER_GREY = '#454545';
// export const DARKEST_GREY = '#303030'; not used atm
export const BLACK_GREY = '#202020';
export const GREEN = '#4bb543';
export const BRIGHT_GREEN = '#a5da4c';
export const YELLOW = '#f7d908';
export const BRIGHT_ORANGE = '#f7b108';
export const ORANGE = '#f48700';
export const RED = '#d82e2e';
export const BLUE = '#91d5ff';

export const theme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
  },

  components: {
    Button,
    Alert,
    DescriptionList,
    Heading,
    Badge,
    Link,
    AlertDialog,
    Accordion,
    Drawer,
    CircularProgress,
    Modal,
    IconButton,
    Table,
    Text,
    ProgressSection,
    HorizontalSection,
    VerticalProgressBar,
    Tooltip,
    Checkbox,
    Popover,
    RadioButton,
    FinacialsGuV: FinancialsGuV,
    FinancialBalanceSheet,
    KpiCard,
    CustomerLogin,
    CustomerLoginHeading,
    CustomerInquiryListHeading,
    SummaryCard,
    SummaryCards,
    PortalPage,
    CustomerLoginPortalPage: PortalPage,
    FieldWrapper,
  },

  colors: {
    background: {
      blackGrey: DARKER_GREY,
      neutral: GREY,
      darkGrey: LIGHTEST_GREY,
      lightGrey: WHITE_GREY,
      white: WHITE,
    },
    border: {
      darkGrey: LIGHTER_GREY,
      lightGrey: LIGHTEST_GREY,
      whiteGrey: WHITE_GREY,
      white: WHITE,
    },
    icon: {
      ok: GREEN,
      danger: RED,
      darkGrey: GREY,
      lightGrey: LIGHT_GREY,
      white: WHITE,
    },
    riskClass: {
      one: GREEN,
      two: BRIGHT_GREEN,
      three: YELLOW,
      four: BRIGHT_ORANGE,
      five: ORANGE,
      six: RED,
      none: LIGHTEST_GREY,
    },
    status: {
      info: BLUE,
      info_L93: setLuminosity(hexToHSL(BLUE), 93),
      success: GREEN,
      success_L93: setLuminosity(hexToHSL(GREEN), 93),
      warning: YELLOW,
      warning_L93: setLuminosity(hexToHSL(YELLOW), 93),
      error: RED,
      error_L93: setLuminosity(hexToHSL(RED), 93),
      error_L83: setLuminosity(hexToHSL(RED), 83),
      error_L70: setLuminosity(hexToHSL(RED), 70),
    },
    text: {
      primary: BLACK_GREY,
      secondary: DARKER_GREY,
      tertiary: DARK_GREY,
      white: WHITE,
    },
    trafficLight: {
      green: GREEN,
      yellow: YELLOW,
      red: RED,
      none: LIGHTEST_GREY,
    },
  },

  styles: {
    global: {
      ...globalStyles,
    },
  },

  radii,
  shadows,
  space,
  textStyles,
  transitions,
  zIndices,
});
