import * as React from 'react';

import { Button, ButtonProps, useToken } from '@chakra-ui/react';

import { BadgeComponent } from '../Badge';

export type VariantType = 'primary' | 'secondary' | 'tertiary' | 'link' | 'error';

type Props = {
  variant?: VariantType;
  isLoading?: boolean;
  disabled?: boolean;
  parentRef?: React.RefObject<HTMLButtonElement>;
  badge?: string;
  href?: string;
  children: React.ReactNode;
  target?: string;
  rel?: string;
  testId?: string;
  to?: string;
} & ButtonProps;

export const ButtonComponent = ({
  variant = 'primary',
  children,
  isLoading,
  disabled,
  parentRef,
  badge,
  testId,
  ...props
}: Props) => {
  const buttonHoveredShadow = (color: string) => `0 2px 10px ${color}`;
  const buttonFocusedShadow = (color: string) => `0 0 0 3px ${color}`;

  const [L83, HalfS_L70, HalfS_L80, Error_L83, Error_L70] = useToken('colors', [
    'brand.default_L83',
    'brand.default_HalfS_L70',
    'brand.default_HalfS_L80',
    'status.error_L83',
    'status.error_L70',
  ]);

  const boxShadowStyle: any = {
    primary: {
      _hover: {
        boxShadow: buttonHoveredShadow(HalfS_L70),
      },

      _focus: {
        boxShadow: buttonFocusedShadow(L83),
      },
    },
    secondary: {
      _hover: {
        boxShadow: buttonHoveredShadow(HalfS_L80),
      },

      _focus: {
        boxShadow: buttonFocusedShadow(L83),
      },
    },
    tertiary: {
      _hover: {
        boxShadow: buttonHoveredShadow(HalfS_L80),
        borderColor: 'brand.default_L83',
        backgroundColor: 'brand.default_L93',
      },

      _focus: {
        boxShadow: buttonFocusedShadow(L83),
      },
    },
    link: {
      _hover: {
        textDecoration: 'underline',
        color: 'brand.default',
      },

      _focus: {
        textDecoration: 'underline',
        color: 'brand.default',
      },
    },
    error: {
      _hover: {
        boxShadow: buttonHoveredShadow(Error_L70),
      },
      _focus: {
        boxShadow: buttonFocusedShadow(Error_L83),
      },
    },
  };

  const variantStyle: any = {
    error: {
      backgroundColor: 'status.error',
      borderColor: 'status.error',
    },
  };

  const stateStyle = {
    ...boxShadowStyle[variant],
    ...variantStyle[variant],
  };

  const style = {
    ...stateStyle,
  };

  return (
    <Button
      isLoading={isLoading}
      isDisabled={disabled || isLoading}
      disabled={disabled || isLoading}
      ref={parentRef}
      variant={variant === 'error' ? 'primary' : variant}
      sx={style}
      data-testid={testId}
      {...props}
    >
      {children}
      {badge && <BadgeComponent ml={2}>{badge}</BadgeComponent>}
    </Button>
  );
};

export default ButtonComponent;
