import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import { hausbankFieldsWithoutParents } from 'hausbank/inquiry/fields';
import { IInquiryDetails } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import {
  FINANCING_AMOUNT,
  FORM_TYPE,
  OFFER_FACTORING_LINE,
  PRODUCT_KIND,
  PURPOSE_KIND,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useInquiryStatusTranslator } from 'modules/Inquiry/inquiryStatuses';
import { useInquiryValueParserForDisplay } from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { VOLUME_OF_SALES } from 'onlinefactoring/formFields';
import { getIdShortcut } from 'pages/operationPortal/InquiryDetails/Dashboard/Summary/InquiryId';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import {
  getIndicativeConditionCompleted,
  getInquiryDetailsSelector,
  getInquiryLane,
} from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPriceForFactoringLine } from 'utils/valueFormats';

import { getIndicativeOffer } from './../../../../store/inquiryDetails/selectors';
import { mittweidaFields } from '../../../../mittweida/inquiry/fields';
import useCurrentInquiryDetails, {
  useCurrentCompanyDetails,
} from '../../../../shared/hooks/inquiry/useCurrentInquiryDetails';
import { getNewInquiryDetails } from '../../../../store/newInquiryDetails/selector';

const formatDate = (date: Date | string | null) =>
  date ? new Date(date).toLocaleString('ru-RU') : '';

const useSummaryCardAmount = () => {
  const t = useTranslations();
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const indicativeConditions = useSelector(getIndicativeOffer);
  const indicativeOfferCompleted = useSelector(getIndicativeConditionCompleted);

  const bfssAmount = indicativeOfferCompleted
    ? formatPriceForFactoringLine(
        _get(indicativeConditions, [OFFER_FACTORING_LINE])?.replace(/\s/g, '')!,
      )
    : t('pages.inquiryDetails.dashboard.actions.indicativeOffer.comingSoon');
  const { financingAmount: hausbankFinancingAmountField } =
    hausbankFieldsWithoutParents.financingNeedPage.financingNeedSection;
  const amount = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: bfssAmount,
    [InquiryType.hausbank]: _get(inquiryDetails, [
      INQUIRY_SECTIONS.INQUIRY_TYPE_SPECIFIC_DETAILS,
      hausbankFinancingAmountField,
    ]),
    [InquiryType.cbBank]: indicativeConditions?.factoringFee
      ? indicativeConditions?.factoringLine
      : undefined,
    default: _get(inquiryDetails, [INQUIRY_SECTIONS.ADDITIONAL_FIELDS, VOLUME_OF_SALES]),
  });
  return amount;
};

const useProductLabel = () => {
  const t = useTranslations();
  const valueTranslator = useInquiryValueParserForDisplay();
  const inquiryDetails = useSelector(getInquiryDetailsSelector) as IInquiryDetails;
  const label = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: t(
      'pages.inquiryDetails.dashboard.actions.nonBindingOffer.factoringLine',
    ),
    [InquiryType.mmv]: inquiryDetails.inquiryTypeSpecificDetails?.objectDescription,
    [InquiryType.hausbank]: t('data.financingProduct.loan'),
    [InquiryType.cbBank]: t(
      'inquiryType.cbBank.pages.financingNeed.sections.factoringNeed.fields.factoringLine.caption',
    ),
    default: valueTranslator(
      _get(inquiryDetails, [INQUIRY_SECTIONS.DETAILS, PRODUCT_KIND]),
      PRODUCT_KIND,
    ),
  });
  return label;
};

export const useSummaryCardData = () => {
  const t = useTranslations();

  const inquiryDetails = useCurrentInquiryDetails();
  const { referenceId } = useCurrentCompanyDetails();
  const newInquiryDetails = useSelector(getNewInquiryDetails);
  const valueTranslator = useInquiryValueParserForDisplay();
  const statusTranslator = useInquiryStatusTranslator();
  const lane = useSelector(getInquiryLane);

  const createdAtRaw = _get(inquiryDetails, 'createdAt') || '';
  const diffTime = new Date().getTime() - new Date(inquiryDetails?.createdAt ?? '').getTime();

  const daysSince = Math.round(diffTime / (1000 * 3600 * 24));
  const hoursSince = Math.round(diffTime / (1000 * 3600));
  const sinceString =
    daysSince !== 0 ? `${daysSince} ${t('other.days')}` : `${hoursSince} ${t('other.hours')}`;

  const amountValue = useSummaryCardAmount();
  const formattedValue = amountValue ? valueTranslator(amountValue, VOLUME_OF_SALES) : '';
  const financefinancingAmount =
    _get(inquiryDetails, [INQUIRY_SECTIONS.FINANCING, FINANCING_AMOUNT]) || null;
  const detailsFinancingAmount =
    _get(inquiryDetails, [INQUIRY_SECTIONS.DETAILS, FINANCING_AMOUNT]) || null;
  const defaultAmount = valueTranslator(
    !financefinancingAmount ? detailsFinancingAmount : financefinancingAmount,
    FINANCING_AMOUNT,
  );
  const amount = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: formattedValue,
    [InquiryType.onlinefactoring]: formattedValue,
    [InquiryType.hausbank]: valueTranslator(formattedValue, FINANCING_AMOUNT),
    [InquiryType.cbBank]: valueTranslator(formattedValue, FINANCING_AMOUNT),
    default: defaultAmount,
  });
  const purposeKind = _get(inquiryDetails, [INQUIRY_SECTIONS.FINANCING, PURPOSE_KIND]);
  const purpose = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: t(
      'pages.requestDetails.sections.financialProduct.fields.productKind.options.factomat.title',
    ),
    default: valueTranslator(purposeKind, PURPOSE_KIND),
  });
  const product = useProductLabel();
  const deadline = useSelectedInquiryTypeSpecificValue({
    [InquiryType.mmv]: t('pages.inquiryDetails.dashboard.summary.days', {
      count: inquiryDetails.expirationInDays,
    }),
    default: null,
  });

  const status = statusTranslator(inquiryDetails.status);
  const formType = valueTranslator(inquiryDetails.formType, FORM_TYPE);

  const contractNumber = inquiryDetails.inquiryTypeSpecificDetails?.contractNumber;
  const supplier = inquiryDetails.inquiryTypeSpecificDetails?.supplier;

  let role = '';
  if (newInquiryDetails?.financingNeedPage?.roleSection?.projectFinancingRole) {
    role = valueTranslator(
      newInquiryDetails.financingNeedPage.roleSection.projectFinancingRole,
      mittweidaFields.financingNeedPage.roleSection.projectFinancingRole,
    );
  }

  const createdAt = formatDate(createdAtRaw);
  const id = getIdShortcut(_get(inquiryDetails, 'inquiryId'));
  const companyName = _get(inquiryDetails, 'company.name');
  return {
    amount,
    associatedCompany: inquiryDetails.associatedCompany,
    product,
    role,
    purpose,
    status,
    createdAt,
    createdAtSince: sinceString,
    id,
    companyName,
    lane,
    formType,
    deadline,
    contractNumber,
    supplier,
    substatus: inquiryDetails.substatus,
    referenceId,
  };
};
