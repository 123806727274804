import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { useForm, useFormState } from 'react-final-form';
import { createQuery } from 'react-query-kit';
import { useSelector } from 'react-redux';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { cbBankFieldsWithSectionString, cbBankFields } from 'cbbank/inquiry/fields';
import queryKeys from 'constants/queryKeys';
import { getCreatedInquiryId } from 'store/progress/selectors';

type BeneficialOwnerAttributes = {
  id: string;
  type: 'beneficiary_owners';
  attributes: {
    additional_info: {
      nationality: string;
    };
    first_name: string;
    last_name: string;
    company_share: string;
    city: string;
    country: string;
    birth_place: string;
    birth_date: string;
    nationality: string;
  };
};

type LegalRepresentativeAttributes = {
  id: string;
  type: 'legal_representatives';
  attributes: {
    salutation: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    birth_date: string;
    additional_info: {
      sole_signature_authorized: boolean;
    };
  };
};

interface Params {
  inquiryId: string;
}

const mapLegalRepresentatives = ({ attributes, id }: LegalRepresentativeAttributes) => ({
  id,
  salutation: attributes?.salutation,
  firstName: attributes?.['first_name'],
  lastName: attributes?.['last_name'],
  email: attributes?.email,
  phoneNumber: attributes?.['phone_number'],
  birthDate: attributes?.['birth_date'],
  soleSignatureAuthorized:
    attributes?.['additional_info']?.['sole_signature_authorized']?.toString() ?? 'false',
});

const mapBeneficiaryOwner = ({ attributes, id }: BeneficialOwnerAttributes) => ({
  id,
  firstName: attributes?.['first_name'],
  lastName: attributes?.['last_name'],
  capitalOrVotingRightsPercentage: attributes?.['company_share'],
  placeOfResidence: attributes?.['city'],
  nationality: attributes.additional_info.nationality,
  countryOfResidence: attributes?.['country'],
  birthPlace: attributes?.['birth_place'],
  birthDate: attributes?.['birth_date'],
});

const fetchLegalRepresentatives = async ({
  queryKey,
}: {
  queryKey: [string, Params];
}): Promise<LegalRepresentativeAttributes[]> => {
  const [, variables] = queryKey;

  const response = await EcoBankingAxiosClientAuthedInstance.get(
    endpoints.INQUIRIES.SPECIALIZED.CB_BANK.GET_LEGAL_REPRESENTATIVES.compose({
      params: { inquiryId: variables.inquiryId },
    }),
  );

  return response.data.data;
};

const fetchBeneficiaryOwners = async ({
  queryKey,
}: {
  queryKey: [string, Params];
}): Promise<BeneficialOwnerAttributes[]> => {
  const [, variables] = queryKey;

  const response = await EcoBankingAxiosClientAuthedInstance.get(
    endpoints.INQUIRIES.SPECIALIZED.CB_BANK.GET_BENEFICIARY_OWNERS.compose({
      params: { inquiryId: variables.inquiryId },
    }),
  );

  return response.data.data;
};

const useRequestBeneficiaryOwners = createQuery<BeneficialOwnerAttributes[], Params>({
  primaryKey: queryKeys.partners.cbBank.beneficiaryOwnersData,
  queryFn: fetchBeneficiaryOwners,
  refetchOnWindowFocus: false,
});

const useRequestLegalRepresentatives = createQuery<LegalRepresentativeAttributes[], Params>({
  primaryKey: queryKeys.partners.cbBank.legalRepresentativesData,
  queryFn: fetchLegalRepresentatives,
  refetchOnWindowFocus: false,
});

export const useRequestAssociatedPeople = (kycDataAvailable: boolean) => {
  const { change } = useForm();
  const { values } = useFormState();
  const boArrayFieldName =
    cbBankFieldsWithSectionString.personalDataPage.beneficialOwnersSection.beneficialOwners
      .sectionString;
  const lrArrayFieldName =
    cbBankFieldsWithSectionString.personalDataPage.legalRepresentativesSection.legalRepresentatives
      .sectionString;
  const inquiryId = useSelector(getCreatedInquiryId);
  const { data: boData, isSuccess: isBoSuccess } = useRequestBeneficiaryOwners({
    variables: { inquiryId },
    enabled: kycDataAvailable,
  });
  const { data: lrData, isSuccess: isLrSuccess } = useRequestLegalRepresentatives({
    variables: { inquiryId },
    enabled: kycDataAvailable,
  });

  const { allInformationAvailable } = cbBankFields.personalDataPage.beneficialOwnersSection;

  if (lrData?.length) {
    const mappedLrData = lrData.map(mapLegalRepresentatives);
    const isLrPrefilled = _isEqual(_get(values, lrArrayFieldName), mappedLrData);
    if (!isLrPrefilled) {
      change(lrArrayFieldName, mappedLrData);
    }
  }

  if (boData?.length) {
    const mappedBoData = boData.map(mapBeneficiaryOwner);
    const isBoPrefilled = _isEqual(_get(values, boArrayFieldName), mappedBoData);
    if (!isBoPrefilled) {
      change(boArrayFieldName, mappedBoData);
      change(allInformationAvailable, 'true');
    }
  }

  return {
    isSuccess: isBoSuccess && isLrSuccess,
  };
};
